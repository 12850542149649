import { useState, useRef, useEffect } from "react";

import { Button, Table, Carousel } from "antd";
import { Typography } from "antd";
import useFormContext from "../../../hooks/useFormContext";
import FixedColumns from "../FixedColumn";
import FixedColumnsSecondStage from "../FixedColumnsSecondStage";
const OptimizerSecondStage = () => {
  const { secondOptimizationFilterParams, designGroups, steel, formworkCost, setFloorLevelValuesSecondStage, setBuildingLevelValuesSecondStage } =
    useFormContext();
  const [showOptimizations, setShowOptimizations] = useState(false);
  const SQUARE = "Square";
  const RECTANGLULAR = "Rectangular";
  const CIRCULAR = "Circular";
  let floorLevelDetails = [];
  let buildingValues = [];

  const calculateAgTrial = (min, short, long, limit) => {
    let ans, columnType;
    if (!(short > min)) short = min;

    if (short > limit) {
      ans = short;
      columnType = "Short";
    } else {
      if (!(long > min)) long = min;
      ans = long;
      columnType = "Slender";
    }
    return [ans, columnType];
  };

  const calculateBdAndHTrial = (comb) => {
    const returnObject = {};

    if (comb.shape === CIRCULAR) {
      returnObject.dTrial = Math.pow((4 / Math.PI) * comb.agTrial, 0.5);
      returnObject.columnShape = CIRCULAR;
    } else {
      returnObject.bTrial = Math.pow(comb.beta * comb.agTrial, 0.5);
      returnObject.hTrial = returnObject.bTrial / comb.beta;
      returnObject.columnShape = RECTANGLULAR;
    }

    return returnObject;
  };

  const calculateIcIswAndItotal = (comb, designGroup) => {
    const area =
      comb.shape === CIRCULAR
        ? (Math.PI * Math.pow(comb.dTrial, 2)) / 4
        : comb.bTrial * comb.hTrial;
    const iC =
      ((1 - 0.01 * comb.pw) * area * designGroup.length * comb.concreteGwp) /
      3.281;
    const iSw =
      (0.01 * comb.pw * area * designGroup.length * steel.gwp * 0.765) / 3.281;
    const iTotal = iC + iSw; // TODO: should i ignore if one of the values is NaN ?

    return [iC, iSw, iTotal];
  };

  const calculateDollarValues = (comb, designGroup) => {
    const area =
      comb.shape === CIRCULAR
        ? (Math.PI * Math.pow(comb.dTrial, 2)) / 4
        : comb.bTrial * comb.hTrial;

    const perimeter =
      comb.shape === CIRCULAR
        ? Math.PI * comb.dTrial
        : 2 * (comb.bTrial + comb.hTrial);

    const dollarC =
      ((1 - 0.01 * comb.pw) * area * designGroup.length * comb.concreteCost) /
      3.281;
    const dollarSw =
      (0.01 * comb.pw * area * designGroup.length * steel.cost * 0.765) / 3.281;

    const dollarFw = (perimeter * designGroup.length * formworkCost*10.76) / 3.281; // multiply formwork to convert to usd/m2
    const dollarTotal = dollarC + dollarSw + dollarFw;

    return [dollarC, dollarSw, dollarFw, dollarTotal];
  };

  const generateDescription = (comb, columnType) => {
    if (comb.shape === CIRCULAR) {
      return (
        Math.ceil(comb.dTrial * 39.37) +
        "in " +
        columnType +
        " circular column w/  spiral reinf & pw = " +
        comb.pw +
        "%"
      );
    }
    return (
      Math.ceil(comb.bTrial * 39.37) +
      "in x " +
      Math.ceil(comb.hTrial * 39.37) +
      "in " +
      columnType +
      " rectangular column w/ tied reinf & pw = " +
      comb.pw +
      "%"
    );
  };

  const getFloor = (range) => {
    let floor = "";
    if (range[1] - range[0] === 0) {
      floor = range[0].toString();
    } else {
      floor = range[0].toString() + "-" + range[1].toString();
    }

    return floor;
  };

  const getNumberOfFloors = (range) => {
    return range[1] - range[0] + 1;
  };

  const roundToTwoDecimal = (floors) => {
    floors.forEach((floor) => {
      floor.combinations.forEach((comb) => {
        comb.iC = Math.ceil(comb.iC);
        comb.iSw = Math.ceil(comb.iSw);
        comb.iTotal = Math.ceil(comb.iTotal);
        comb.dollarC = Math.ceil(comb.dollarC);
        comb.dollarSw = Math.ceil(comb.dollarSw);
        comb.dollarFw = Math.ceil(comb.dollarFw);
        comb.dollarTotal = Math.ceil(comb.dollarTotal);
      });
    });
    return floors;
  };

  const initializeFloorValues = (secondDesignGroups) => {
    let floorAggregates = [];
    secondDesignGroups.forEach((designGroup) => {
      const floor = getFloor(designGroup.floorRange);
      const floorAggregate = floorAggregates.find((ele) => ele.floor === floor);
      if (!floorAggregate) {
        const floorObj = {
          floor: floor,
          combinations: [],
          numberOfFloors: getNumberOfFloors(designGroup.floorRange),
        };
        for (let i = 0; i < designGroup.combinations.length; i++) {
          const comb = {
            iC: 0,
            iSw: 0,
            iTotal: 0,
            dollarC: 0,
            dollarSw: 0,
            dollarFw: 0,
            dollarTotal: 0,
            designGroups: [],
          };
          floorObj.combinations.push(comb);
        }
        floorAggregates.push(floorObj);
      }
    });
    return floorAggregates;
  };

  const calculateFloorValues = (secondDesignGroups) => {
    const floorAggs = initializeFloorValues(secondDesignGroups);
    secondDesignGroups.forEach((designGroup) => {
      const floorAgg = floorAggs.find(
        (ele) => ele.floor === getFloor(designGroup.floorRange)
      );
      
      const combinations = floorAgg.combinations;

      for (let i = 0; i < designGroup.combinations.length; i++) {
        combinations[i].product = designGroup.combinations[i].product;
        combinations[i][designGroup.designGroup] =
          designGroup.combinations[i].description;
        combinations[i].iC +=
          designGroup.combinations[i].iC * designGroup.numberOfColumns;
        combinations[i].iSw +=
          designGroup.combinations[i].iSw * designGroup.numberOfColumns;
        combinations[i].iTotal +=
          designGroup.combinations[i].iTotal * designGroup.numberOfColumns;
        combinations[i].dollarC +=
          designGroup.combinations[i].dollarC * designGroup.numberOfColumns;
        combinations[i].dollarSw +=
          designGroup.combinations[i].dollarSw * designGroup.numberOfColumns;
        combinations[i].dollarFw +=
          designGroup.combinations[i].dollarFw * designGroup.numberOfColumns;
        combinations[i].dollarTotal +=
          designGroup.combinations[i].dollarTotal * designGroup.numberOfColumns;
        combinations[i].id = i;

        const designGroupObj = {
          name: designGroup.designGroup,
          bTrial: designGroup.combinations[i].bTrial,
          hTrial: designGroup.combinations[i].hTrial,
          dTrial: designGroup.combinations[i].dTrial,
          pw: designGroup.combinations[i].pw,
          length: designGroup.length,
          numberOfColumns: designGroup.numberOfColumns,
          beta: designGroup.combinations[i].beta,
          columnShape: designGroup.combinations[i].columnShape,
        };
        combinations[i].designGroups.push(designGroupObj);
      }
    });
    console.log("After floor agg - ", floorAggs);
    const roundedFloorAggs = roundToTwoDecimal(floorAggs);
    return roundedFloorAggs;
  };

  const initBuildingValues = (numberOfCombinations) => {
    let buildingValues = [];

    for (let i = 0; i < numberOfCombinations; i++) {
      const comb = {
        iC: 0,
        iSw: 0,
        iTotal: 0,
        dollarC: 0,
        dollarSw: 0,
        dollarFw: 0,
        dollarTotal: 0,
      };

      buildingValues.push(comb);
    }

    return buildingValues;
  };

  const calculateBuildingValues = (floorLevelDetails) => {
    const buildingValues = initBuildingValues(
      floorLevelDetails[0].combinations.length
    );

    floorLevelDetails.forEach((floor) => {
      for (let i = 0; i < floor.combinations.length; i++) {
        buildingValues[i].iC +=
          (floor.combinations[i].iC * floor.numberOfFloors) / 1000;
        buildingValues[i].iSw +=
          (floor.combinations[i].iSw * floor.numberOfFloors) / 1000;
        buildingValues[i].iTotal +=
          (floor.combinations[i].iTotal * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarC +=
          (floor.combinations[i].dollarC * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarSw +=
          (floor.combinations[i].dollarSw * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarFw +=
          (floor.combinations[i].dollarFw * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarTotal +=
          (floor.combinations[i].dollarTotal * floor.numberOfFloors) / 1000;
        buildingValues[i].product = floor.combinations[i].product;
        buildingValues[i].id = i;
      }
    });

    console.log("Building values before state set: ", buildingValues);
    const roundedValues = round(buildingValues);
    return roundedValues;
  };

  const round = (values) => {
    values.forEach((comb) => {
      comb.iC = Math.ceil(comb.iC);
      comb.iSw = Math.ceil(comb.iSw);
      comb.iTotal = Math.ceil(comb.iTotal);
      comb.dollarC = Math.ceil(comb.dollarC);
      comb.dollarSw = Math.ceil(comb.dollarSw);
      comb.dollarFw = Math.ceil(comb.dollarFw);
      comb.dollarTotal = Math.ceil(comb.dollarTotal);
    });
    return values;
  };

  useEffect(() => {
    // do the filter operations and replace amin according to the conditions that are mandated by the second stage of optimization

    const secondDesignGroups = filterAndReplaceAminFromDesignGroups();

    // we have to repeat the steps to calculate the values like bTrial, hTrial etc because now aGmin has been modified
    /**
     * aGtrial is dependent on aGmin
     * bTrial, hTrial etc is dependent on aGtrial
     *
     */
    secondDesignGroups.forEach((designGroup) => {
      designGroup.combinations.forEach((comb) => {
        const returnFromTrialCalculation = calculateAgTrial(
          comb.aGmin,
          comb.aGshort,
          comb.aGlong,
          comb.aGlimit
        );
        comb.agTrial = returnFromTrialCalculation[0];
        comb.columnType = returnFromTrialCalculation[1];

        const bDandHtrial = calculateBdAndHTrial(comb);
        comb.bTrial = Math.ceil(bDandHtrial.bTrial * 20) / 20;
        comb.dTrial = Math.ceil(bDandHtrial.dTrial * 20) / 20;
        comb.hTrial = Math.ceil(bDandHtrial.hTrial * 20) / 20;
        comb.columnShape = bDandHtrial.columnShape;

        const iCIswAndItotal = calculateIcIswAndItotal(comb, designGroup);
        comb.iC = iCIswAndItotal[0].toFixed(2);
        comb.iSw = iCIswAndItotal[1].toFixed(2);
        comb.iTotal = iCIswAndItotal[2].toFixed(2);

        const dollarValues = calculateDollarValues(comb, designGroup);
        comb.dollarC = dollarValues[0].toFixed(2);
        comb.dollarSw = dollarValues[1].toFixed(2);
        comb.dollarFw = dollarValues[2].toFixed(2);
        comb.dollarTotal = dollarValues[3].toFixed(2);

        comb.description = generateDescription(comb, comb.columnType);
      });
    });

    console.log("After re calculating ", secondDesignGroups);

    // At this point, the combinations have been recalculated, now we need to re calculate floor and building values
    floorLevelDetails = calculateFloorValues(secondDesignGroups);
    setFloorLevelValuesSecondStage(floorLevelDetails);
    console.log("Floor level calcs are ", floorLevelDetails);
    buildingValues = calculateBuildingValues(floorLevelDetails);
    setBuildingLevelValuesSecondStage(buildingValues);
  }, []);

  const filterAndReplaceAminFromDesignGroups = () => {
    const designGroupsCopy = designGroups.map((group) => {
      // Find the corresponding filter parameters based on designGroupName
      const filterParams = secondOptimizationFilterParams.find((param) =>
        param.hasOwnProperty(group.designGroup)
      );
      const newGroup = { ...group };

      if (filterParams) {
        const designGroupName = group.designGroup;
        const rho1 = filterParams[designGroupName].minRho1;
        const rho2 = filterParams[designGroupName].minRho2;
        const area1 = filterParams[designGroupName].minArea1;
        const area2 = filterParams[designGroupName].minArea2;

        // Calculate the maximum of area1 and area2
        const maxArea = Math.max(area1, area2);
        // Filter and map the combs array
        newGroup.combinations = group.combinations
          .filter((comb) => comb.pw >= rho1 && comb.pw >= rho2)
          .map((comb) => ({
            ...comb,
            // Replace amin with maxArea if amin is smaller than area1 and area2
            aGmin:
              comb.aGmin < area1 || comb.aGmin < area2 ? maxArea : comb.aGmin,
          }));
      }

      return newGroup;
    });

    console.log("Before re calculating and after filtering ", designGroupsCopy);
    return designGroupsCopy;
  };

  const dsiplayOptimizations = () => {
    setShowOptimizations(true);
  };

  return (
    <div style={{ margin: 10 }}>
      {!showOptimizations && (
        <Button onClick={dsiplayOptimizations}> Show optimizations</Button>
      )}
      {showOptimizations && floorLevelDetails && buildingValues && (
        // <FixedColumns
        //   floorLevelValues={floorLevelDetails}
        //   buildingLevelValues={buildingValues}
        // />
        <FixedColumnsSecondStage />
      )}
    </div>
  );
};

export default OptimizerSecondStage;
