import React, { useState, useRef } from "react";
import { Typography, Table, Checkbox, Col, InputNumber } from "antd";
import useFormContext from "../../../hooks/useFormContext";
const {Title} = Typography;
const LoadInput = () => {
  // global context variables
  const { designGroups, setDesignGroups } = useFormContext();
  const INT_COL = "Interior Columns";
  const EXT_COL = "Exterior Columns";

  const handleChange = (record, col, value) => {
    const udpatedDesignGroups = [...designGroups];
    const recordIndex = udpatedDesignGroups.findIndex(
      (item) => item.designGroup === record.designGroup
    );
    udpatedDesignGroups[recordIndex][col] = value;

    setDesignGroups(udpatedDesignGroups);

    console.log("New designGroup is ", designGroups);
  };
  const useDefaultValue = () => {
    const newDesignGroups = [...designGroups];
    newDesignGroups.forEach((obj) => {
      obj.cm = 1;
      if (obj.floorRange[0] === 3) {
        if (obj.columnGroups == EXT_COL) {
          obj.du = 577.76;
          obj.pu = 683.42;
        } else {
          obj.du = 1040.87;
          obj.pu = 1227.46;
        }
      } else {
        if (obj.columnGroups == EXT_COL) {
          obj.du = 699.16;
          obj.pu = 827.3;
        } else {
          obj.du = 1258.93;
          obj.pu = 1483.74;
        }
      }
    });
    setDesignGroups(newDesignGroups);
    console.log("after chckebox is ticked, designgroups are ", designGroups);
  };
  const columns = [
    {
      title: "Design Group",
      dataIndex: "designGroup",
      key: "designGroup",
    },
    {
      title: "Cm",
      dataIndex: "cm",
      key: "cm",
      render: (_, record) => (
        <InputNumber
          value={record.cm}
          onChange={(value) => handleChange(record, "cm", value)}
        />
      ),
    },
    {
      title: "Du (kips)",
      dataIndex: "du",
      key: "du",
      render: (_, record) => (
        <InputNumber
          value={record.du}
          onChange={(value) => handleChange(record, "du", value)}
        />
      ),
    },
    {
      title: "Pu (kips)",
      dataIndex: "pu",
      key: "pu",
      render: (_, record) => (
        <InputNumber
          value={record.pu}
          onChange={(value) => handleChange(record, "pu", value)}
        />
      ),
    },
  ];

  return (
    <div style={{margin: 10}}>
      <Title level = {3} style={{textAlign: "left"}}>Load Input</Title>
      <Table
        style={{ marginTop: "10px" }}
        dataSource={designGroups}
        columns={columns}
        pagination={true}
      />
      <div>
        <Checkbox onChange={useDefaultValue}>Use default value</Checkbox>
      </div>
    </div>
  );
};

export default LoadInput;
