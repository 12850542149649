import React, { useState, useEffect } from "react";
import {
  InputNumber,
  Form,
  Button,
  Select,
  Typography,
  Table,
  Switch,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import useFormContext from "../../../hooks/useFormContext";
const {Title} = Typography;
const DesignInputForm = () => {
  const [form] = Form.useForm();
  const { designGroups, setDesignGroups, setMomentMagnifier } =
    useFormContext();
  const [radioValue, setRadioValue] = useState(1);

  const onFinish = (values) => {
    setMomentMagnifier(values.momentMagnifier);
  };

  const handleChange = (record, col, value) => {
    const updatedDesignGroups = [...designGroups];
    const recordIndex = updatedDesignGroups.findIndex(
      (item) => item.designGroup === record.designGroup
    );
    updatedDesignGroups[recordIndex][col] = value;
    setDesignGroups(updatedDesignGroups);
    console.log("Design Groups are ", designGroups);
  };

  useEffect(() => {
    fillDefaultValues();
  }, []);


  const fillDefaultValues = () => {
    const newDesignGroups = [...designGroups];
    newDesignGroups.forEach((obj) => {
      obj.concreteCover = 1.5;
      obj.eccOfAxialLoad = 0;
      obj.minColDiameter = 10;
      obj.minColWidth = 10;
      obj.trialArea = 3.36;
    });
    setDesignGroups(newDesignGroups);
  };


  const convertToFeet = (valueInInches) => {
    const valueInFeet = valueInInches / 12; // 1 foot = 12 inches
    return valueInFeet.toFixed(2) + " ft";
  };


  const columns = [
    {
      title: "Design Group",
      dataIndex: "designGroup",
      key: "designGroup",
    },
    {
      title: "Concrete Cover (in)",
      dataIndex: "concreteCover",
      key: "concreteCover",
      render: (_, record) => (
        <span>
          <InputNumber
            value={1.5}            
            onChange={(value) => handleChange(record, "concreteCover", value)}
          />
          <Tooltip title={convertToFeet(record.concreteCover)} color="#f50" placement="bottom">
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>

      ),
    },
    {
      title: "Eccentricity of axial load (in)",
      dataIndex: "eccOfAxialLoad",
      key: "eccOfAxialLoad",
      render: (_, record) => (
        <span>
        <InputNumber
          value={0}          
          onChange={(value) => handleChange(record, "eccOfAxialLoad", value)}
        />
        <Tooltip title={convertToFeet(record.eccOfAxialLoad)} color="#f50" placement="bottom">
          <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
        </Tooltip>
      </span>
      ),
    },
    {
      title: "Minimum column diameter (in)",
      dataIndex: "minColDiameter",
      key: "minColDiameter",
      render: (_, record) => (
        <span>
          <InputNumber
            value={10}            
            onChange={(value) => handleChange(record, "minColDiameter", value)}
          />
          <Tooltip title={convertToFeet(record.minColDiameter)} color="#f50" placement="bottom">
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: "Minimum Column Width (in)",
      dataIndex: "minColWidth",
      key: "minColWidth",
      render: (_, record) => (
        <span>
          <InputNumber
            value={10}            
            onChange={(value) => handleChange(record, "minColWidth", value)}
          />
          <Tooltip title={convertToFeet(record.minColWidth)} color="#f50" placement="bottom">
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: <span>Draft Area (ft<sup>2</sup>) </span>,
      dataIndex: "trialArea",
      key: "trialArea",
      render: (_, record) => (
        <span>
          <InputNumber  
            value = {3.36}    
            onChange={(value) => handleChange(record, "concreteCover", value)}
          />
          <Tooltip title={convertToFeet(record.concreteCover)} color="#f50" placement="bottom">
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <div style={{margin: 10}}>
      <Title level = {3} style={{textAlign: "left"}}>Design Input</Title>
      <Form
        form={form}
        onFinish={onFinish}
        style={{ alignItems: "center", justifyContent: "center" }}
        initialValues={{ minColDiameter: 10, minColWidth: 15 }}
      >
        <Form.Item
          label="Moment Magnifier"
          name="momentMagnifier"
          initialValue={1.35}
        >
          <InputNumber prefix="&delta; = " />
        </Form.Item>
        <Form.Item>
          <Table
            style={{ marginTop: "5px" }}
            dataSource={designGroups}
            columns={columns}
            pagination={true}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DesignInputForm;
