import { Button, message, Steps, theme, Space, Layout } from "antd";
import React, { useEffect, useState } from "react";

import SteelReinforcementInput from "./SteelReinforcementInput/SteelReinforcementInput";

import DisplayMatrixTabs from "../DisplayComponents/DisplayMatrixTabs";
import Plots from "../DisplayComponents/Plots";
import useFormContext from "../../hooks/useFormContext";
import {
  checkInput,
  checkLoadInput,
  checkSlendernessInput,
  checkSteelReinforcementSection,
} from "../../utils/errorValidations";
import MaterialInput from "./ConcreteInput/MaterialInput";
import Optimizer from "../DisplayComponents/Optimizer";
import OptionSelector from "./ConcreteInput/OptionSelector";
import SelectedMaterials from "./ConcreteInput/SelectedMaterials";
import VerticalPartitionForm from "./FloorPlanInput/VerticalPartitionForm";
import ColumnPartitionTab from "./FloorPlanInput/ColumnPartitionTab";
import FloorPlanInfoPart3 from "./FloorPlanInput/FloorPlanInputPart3";
import SlendernessInputForm from "./DesignInputs/SlendernessInputForm";
import DesignInputForm from "./DesignInputs/DesignInputForm";
import LoadInput from "./DesignInputs/LoadInput";
import FormworkInput from "./SteelReinforcementInput/FormworkInput";
import Questionnaire from "./SecondStageOptimizer/Questionnaire";
import QuestionsPage from "./SecondStageOptimizer/QuestionsPage";
import OptimizerSecondStage from "../DisplayComponents/SecondStageOptimization/OptimizerSecondStage";
import RebarInput from "./SteelReinforcementInput/RebarInput";
const { Sider, Content, Footer } = Layout;

const UserInputFormBeta = () => {
  const { token } = theme.useToken();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentSubStep, setCurrentSubStep] = useState(0);

  const {
    steel,
    concreteInfo,
    selectedMaterials,
    floorPlanInfo,
    designGroups,
    shapes,
    formworkCost,
    secondOptimizationFilterParams,
    materialsInputPereference
  } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();


  const ec3SearchSteps = [
    { title: "Put", content: <MaterialInput /> },
    {
      title: "Add Cost",
      content: <SelectedMaterials selectedMaterials={selectedMaterials} />,
    }
  ]

  const researchPaperSteps = [
    {
      title: "Add Cost",
      content: <SelectedMaterials selectedMaterials={selectedMaterials} />,
    }
  ]

  const subSteps = materialsInputPereference == 1 ? ec3SearchSteps : researchPaperSteps;

  const steps = [
    {
      title: "Concrete Input",
      subSteps: [
        { title: "Choose", content: <OptionSelector /> },
        ...subSteps
      ],
    },
    {
      title: "Formwork Input",
      subSteps: [
        {
          title: "Formwork cost",
          content: <FormworkInput />,
        },
      ],
    },

    {
      title: "Steel Input",
      subSteps: [{ title: "Choose", content: <SteelReinforcementInput /> },
      { title: "Choose", content: <RebarInput /> }
    ],
    },
    {
      title: "Floor Plan Input",
      subSteps: [
        { title: "Vertical Partition", content: <VerticalPartitionForm /> },
        { title: "Vertical Partition", content: <ColumnPartitionTab /> },
        { title: "Vertical Partition", content: <FloorPlanInfoPart3 /> },
      ],
    },
    {
      title: "Design Input",
      subSteps: [
        { title: "what", content: <SlendernessInputForm /> },
        { title: "what", content: <DesignInputForm /> },
        { title: "what", content: <LoadInput /> },
      ],
    },
    {
      title: "Stage 1 Optimization ",
      subSteps: [{ title: "what", content: <Optimizer /> }],
    },
    {
      title: "Plots",
      subSteps: [{ title: "what", content: <Plots /> }],
    },
    {
      title: "Summary",
      subSteps: [{ title: "what", content: <DisplayMatrixTabs /> }],
    },
    {
      title: "Stage 2 Optimization ",
      subSteps: [{ title: "what", content: <QuestionsPage /> },{ title: "what", content: <OptimizerSecondStage /> }],
    },
  ];

  const throwError = (errorMessage) => {
    messageApi.open({
      type: "error",
      content: errorMessage,
    });
  };

  const decodeError = (errorObject) => {
    console.log(errorObject);
    if (!errorObject.isValid) {
      throwError(errorObject.errorMessage);
      return false;
    }
    return true;
  };

  const checkErrorValidations = (move, currentStep, currentSubStep) => {
    switch (currentStep) {
      case 0:
        switch (currentSubStep) {
          case 1:
            move = decodeError(
              checkInput(selectedMaterials, "Select at least one material")
            );
            break;
          case 2:
            move = decodeError(
              checkInput(concreteInfo, "Finalize before proceeding")
            );
            break;
        }
        break;
      case 1:
        move = decodeError(
          checkInput(formworkCost, "Add formwork cost to proceed")
        );
        break;

      case 2:
        switch (currentSubStep) {
          case 0:
            move = decodeError(checkSteelReinforcementSection(steel));
            break;
        }

        break;
      case 3:
        switch (currentSubStep) {
          case 0:
            move = decodeError(checkInput(floorPlanInfo, "Floor plan missing"));
            break;
          case 1:
            move = decodeError(
              checkInput(designGroups, "Design groups missing")
            );
            break;
          case 2:
            move = decodeError(checkInput(shapes, "Select at least one shape"));
            break;
        }
        break;
      case 4:
        switch (currentSubStep) {
          case 0:
            move = decodeError(
              checkSlendernessInput(
                designGroups,
                "Enter details for all design groups"
              )
            );
            break;
          case 1:
            move = true; // what validations for this step ?
            break;
          case 2:
            move = decodeError(
              checkLoadInput(
                designGroups,
                "Enter details for all design groups"
              )
            );
            break;
        }
        break;
      case 8:
        move = decodeError(
          checkInput(
            secondOptimizationFilterParams,
            "Press save before proceeding"
          )
        );
        break;
    }

    return move;
  };

  const navigate = (direction) => {
    let newCurrentStep = currentStep;
    let newCurrentSubStep = currentSubStep;

    if (direction === "next") {
      let canMove = true;
      canMove = checkErrorValidations(canMove, currentStep, currentSubStep);

      if (canMove) {
        const currentSubSteps = steps[currentStep].subSteps;
        const isLastSubStep = currentSubStep === currentSubSteps.length - 1;

        if (isLastSubStep) {
          if (currentStep !== steps.length - 1) {
            // Move to the first substep of the next step
            newCurrentStep = currentStep + 1;
            newCurrentSubStep = 0;
          }
        } else {
          // Move to the next substep within the same step
          newCurrentSubStep = currentSubStep + 1;
        }
      }
    } else if (direction === "prev") {
      const isFirstSubStep = currentSubStep === 0;

      if (isFirstSubStep) {
        if (currentStep !== 0) {
          // Move to the last substep of the previous step
          newCurrentStep = currentStep - 1;
          newCurrentSubStep = steps[newCurrentStep].subSteps.length - 1;
        }
      } else {
        // Move to the previous substep within the same step
        newCurrentSubStep = currentSubStep - 1;
      }
    }

    // Update the step and substep state
    setCurrentStep(newCurrentStep);
    setCurrentSubStep(newCurrentSubStep);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  const siderStyle = {
    display: "flex", // Make the container a flex container
    flexDirection: "column", // Stack child elements vertically
    justifyContent: "center", // Center content vertically
    textAlign: "center",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: "white",
    marginTop: 5,
    paddingTop: 15,
  };

  const newCotentStyle = {
    textAlign: "center",

    marginTop: 5,
    height: "80vh",
    overflowY: "auto",
  };

  const Buttons = () => {
    return (
      <div style={newButtonsContainerStyle}>
        <Button
          style={{ margin: 3 }}
          onClick={() => navigate("prev")}
          disabled={currentStep === 0 && currentSubStep === 0}
        >
          Previous
        </Button>
        <Button style={{ margin: 3 }} onClick={() => navigate("next")}>
          Next
        </Button>
      </div>
    );
  };

  const newButtonsContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div>
      {contextHolder}
      <Layout style={{ height: "90vh" }}>
        <Sider style={siderStyle}>
          <Steps
            style={{ padding: 10 }}
            current={currentStep}
            items={items}
            direction="vertical"
          />
        </Sider>
        <Layout>
          <Content style={newCotentStyle}>
            <div>{steps[currentStep].subSteps[currentSubStep].content}</div>
          </Content>
          <Footer>
            <Buttons />
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default UserInputFormBeta;
