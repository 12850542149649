import React, { useState, useRef } from "react";
import { Carousel, Button, Table, Row, Col, Popconfirm,Typography } from "antd";

import ColumnPartitionInput from "./ColumnPartitionInput";
import useFormContext from "../../../hooks/useFormContext";
const {Title} = Typography;
const ColumnPartitionTab = () => {
  // global context variables
  const { floorPlanInfo, designGroups, setDesignGroups } = useFormContext(); // {group: "Group name", floorRange: [from,to]}

  {/* your idea for floorPlanInfo - 

  [
    {
        "group": "A",
        "start": 1,
        "end": 4,
        "isSame": true,
        dsdsa",
                "floorRange": [1,4],
                "columnLength": 5
            },"designGroups": [
            {
                "id": "
            {
                "id": "dsdsa",
                "start": 1,
                "end": 4,
                "columnLength": 5
            }
        ]
    },
    {
        "group": "B",
        "start": 5,
        "end": 8,
        "isSame": false,
        "designGroups": [
            {
                "id": "dsdsa",
                "floorRange": [1,1],
                "columnLength": 5
            },
            {
                "id": "dsadsa",
                "start": 2,
                "end": 2,
                "columnLength": 5
            }
        ]
    }
]

*/}
  // local variables -------------
  const carouselRef = useRef();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const handleNext = () => {
    if (currentGroupIndex < floorPlanInfo.length - 1) {
      carouselRef.current.next();
      setCurrentGroupIndex(currentGroupIndex + 1);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedDesignGroups = [...designGroups];
    updatedDesignGroups.splice(index, 1);
    setDesignGroups(updatedDesignGroups);
  };

  const columns = [
    {
      title: "Floor",
      dataIndex: "floorRange",
      key: "floorRange",
      render: (floorRange) => {
        if (!(floorRange[1] - floorRange[0] === 0))
          return `${floorRange[0]}-${floorRange[1]}`;
        else return `${floorRange[0]}`;
      },
    },
    {
      title: "Column Group",
      dataIndex: "columnGroups",
      key: "columnGroups",
    },
    {
      title: "Design Group",
      dataIndex: "designGroup",
      key: "designGroup",
    },
    {
      title: "Number of Columns",
      dataIndex: "numberOfColumns",
      key: "numberOfColumns",
    },
    {
      title: "Length (ft)",
      dataIndex: "length",
      key: "length",
    },
    {
      title: "Column-ends boundary conditions",
      dataIndex: "boundaryConditions",
      key: "boundaryConditions",
    },
    {
      title: "Effective Unsupported Length (ft)",
      dataIndex: "unspportedLength",
      key: "unspportedLength",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => (
        <Popconfirm
          title="Are you sure you want to remove this row?"
          onConfirm={() => handleRemoveRow(index)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Remove</Button>
        </Popconfirm>
      ),
    },
  ];

  const saveDesignGroupsToContext = () => {
    console.log("doing");
  };

  return (
    <div style={{margin: 10}}>
      <Title level = {3} style={{textAlign: "left"}}>Group Partition</Title>
      <Row>
        <Col span={12}>
          <Carousel ref={carouselRef}>
            {floorPlanInfo.map((group, index) => (
              <div>
                <ColumnPartitionInput
                  groupName={group.group}
                  start={group.start}
                  end={group.end}
                  index={index}
                />
              </div>
            ))}
          </Carousel>
          {currentGroupIndex === floorPlanInfo.length - 1 ? (
            <Button onClick={saveDesignGroupsToContext}>Save Groups</Button>
          ) : (
            <Button onClick={handleNext}>Next Group</Button>
          )}
        </Col>
        <Col span={12}>
          <Table
            style={{ marginTop: "10px", overflowX: "auto"}}
            dataSource={designGroups}
            columns={columns}
            pagination={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ColumnPartitionTab;
