import { Button, Form, InputNumber, message } from "antd";
import { React, useEffect } from "react";
import useFormContext from "../../../hooks/useFormContext";

const Questionnaire = ({ designGroup }) => {
  const [form] = Form.useForm();
  const { secondOptimizationFilterParams, setSecondOptimizationFilterParams } =
    useFormContext();
  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = (values) => {
    setSecondOptimizationFilterParams((prevParams) => [
      ...prevParams,
      {
        [designGroup]: {
          minRho1: values.minRho1,
          minRho2: values.minRho2,
          minArea1: values.minArea1,
          minArea2: values.minArea2,
        },
      },
    ]);

    message.success(
      "Fill details for all design groups before clicking next button"
    );
    console.log(secondOptimizationFilterParams);
  };

  useEffect(() => {
    setSecondOptimizationFilterParams([]);
  }, []);

  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        style={{ margin: 10 }}
        onFinish={handleSubmit}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name="minRho1"
          label={`Minimum \u03C1 (rho) determined from P-M analysis`}
          rules={[
            {
              type: "number",
              min: 1,
              max: 4,
              message: "Value must be between 1 and 4",
            },
          ]}
        >
          <InputNumber required addonAfter="%" />
        </Form.Item>
        <Form.Item
          name="minRho2"
          label={`Minimum \u03C1 (rho) determined during punching shear analysis`}
          rules={[
            {
              type: "number",
              min: 1,
              max: 4,
              message: "Value must be between 1 and 4",
            },
          ]}
        >
          <InputNumber required addonAfter="%" />
        </Form.Item>
        <Form.Item
          name="minArea1"
          label="Minimum area determined during P-M analysis"
        >
          <InputNumber addonAfter="m2" required />
        </Form.Item>
        <Form.Item
          name="minArea2"
          label="Minimum area determined during punching shear analysis"
        >
          <InputNumber addonAfter="m2" required />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">Save</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Questionnaire;
