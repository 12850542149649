import { Button, Typography, Spin, Carousel } from "antd";
import React, { useState, useEffect, useRef } from "react";
import useFormContext from "../../hooks/useFormContext";
import { getMinFromBuilding } from "../../utils/buildingCalculations";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import {
  assignAtrialAndRecalculate,
  getDesignsWithAreaLessThanOrEqualToFloorOne,
  getMinimumAreas,
  isFloorOne,
  addDescriptionToDesignGroup,
  getColumnDimensionsFromFloorOne,
  getDesignWithColumnLengthLessThanOrEqualToFloorOne,
  assignColumnDimensionsAndRecalculate,
  getDesignWithColumnLengthLessThanOrEqualToFloorOneForFixedMixOptimization,
} from "../../utils/floorCalculations";
import OptimizedSummary from "./OptimizedSummary";
import TempTable from "./TempTable";

const { Title } = Typography;
const FixedColumns = ({floorLevelValues, buildingLevelValues}) => {
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [optimalFloorOne, setOptimalFloorOne] = useState({});
  const [temp1, setTemp1] = useState([]);
  const [temp2, setTemp2] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const carouselRef = useRef();
  const {
    floorValues,
    concreteInfo,
    steel,
    optimalDesigns,
    setOptimalDesigns,
    buildingValues,
  } = useFormContext();
  const [constantConcreteOptimization, setConstantConcreteOptimization] =
    useState([]);

  useEffect(() => {
    optimize();
    const timer = setTimeout(() => {
      setIsLoading(false);
      setShowTable(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const optimize = () => {
    console.log("Props are ", floorLevelValues, buildingLevelValues);
    setIsLoading(true);
    const temp1 = []; // temp tables to be displayed
    const temp2 = [];

    const floorOne = floorLevelValues.find((floor) => isFloorOne(floor.floor)); // extract first floor object
    const optimalFloorOne = getSmallestFromFloorX(
      floorOne.combinations,
      floorOne.floor
    );
    const smallestDesigns = [];

    console.log("Lowest from floor one ", optimalFloorOne);
    smallestDesigns.push(optimalFloorOne);

    setOptimalFloorOne(optimalFloorOne); // doing it for temp table
    // get the min area which will be used to compare for other floors
    const minAreas = getMinimumAreas(optimalFloorOne);

    const minDimensions = getColumnDimensionsFromFloorOne(optimalFloorOne);

    console.log("Min col dimensions from floor one are ", minDimensions);
    floorLevelValues.forEach((element) => {
      if (!isFloorOne(element.floor)) {
        // from other floors, get designs whose area less than floor one design
        const eligibleDesigns = getDesignsWithAreaLessThanOrEqualToFloorOne(
          minAreas,
          element
        );
        console.log("Old eligible items are ", eligibleDesigns);
        const eligibleNewDesigns =
          getDesignWithColumnLengthLessThanOrEqualToFloorOne(
            element,
            minDimensions
          );
        temp1.push(eligibleNewDesigns);

        console.log("New eligible items are ", eligibleNewDesigns);
        // now for these floors, recalculate the properties
        // const newCombs = assignAtrialAndRecalculate(
        //   minAreas,
        //   eligibleDesigns,
        //   concreteInfo,
        //   steel
        // );

        // re-calculate with new but old method
        const newNewCombs = assignColumnDimensionsAndRecalculate(
          minDimensions,
          eligibleNewDesigns,
          concreteInfo,
          steel
        );
        temp2.push(newNewCombs);
        console.log("New calculations are ", newNewCombs);

        // now get the design with smallest iTotal
        //const smallestDesign = getSmallestFromFloorX(newCombs, element.floor);

        const smallestDesignNewMethod = getSmallestFromFloorX(
          newNewCombs,
          element.floor
        );

        console.log("Smallest with new calcs is ", smallestDesignNewMethod);

        //console.log("Smallest from this floor is ", smallestDesign);

        smallestDesigns.push(smallestDesignNewMethod);
      }
    });
    setTemp1(temp1);
    setTemp2(temp2);
    setOptimalDesigns(smallestDesigns);

    console.log("Optmial designs are ", optimalDesigns);

    // 3rd opmtimization
    // TODO: not sure how this optimization works
    /**
     * This is my logic - get min from floor one - recrod its mix id and dimensions
     * for subsequent floors, choose the design with that mix id and column dimensions less than or equal to floor one
     */
    let correspondingFloors = [];
    correspondingFloors.push(optimalFloorOne);
    floorValues.forEach((floor) => {
      if (!isFloorOne(floor.floor)) {

        const combsWithSameOrLessDimensions =
        getDesignWithColumnLengthLessThanOrEqualToFloorOneForFixedMixOptimization(
          floor.combinations,
          minDimensions
        );
        const combinationsWithMixDesignAsFloorOne = combsWithSameOrLessDimensions.filter(obj => obj.product == optimalFloorOne.product);

        // now from this get the least Ic design from these
        const smallestFromThisFloor = getSmallestFromFloorX(combinationsWithMixDesignAsFloorOne, floor.floor);
        addDescriptionToDesignGroup(smallestFromThisFloor);
        correspondingFloors.push(smallestFromThisFloor);
      }
    });
    setConstantConcreteOptimization(correspondingFloors);

    // TODO: below is the old logic

    // const smallestBuildingValue = getMinFromBuilding(buildingLevelValues);
    // let correspondingFloors = [];
    // floorLevelValues.forEach((floor) => {
    //   const correspondingFloor = floor.combinations.find(
    //     (obj) => obj.id == smallestBuildingValue.id
    //   );

      
    //   correspondingFloor.floor = floor.floor;
    //   correspondingFloors.push(correspondingFloor);
    // });


    console.log("conctant concrete are ", correspondingFloors);
    setIsLoading(false);
    setShowTable(true);
  };

  const getSmallestFromFloorX = (combinations, floor) => {
    const smallestDesign = combinations.reduce(
      (designWithMinItotal, currentDesign) => {
        const minITotal = parseFloat(designWithMinItotal.iTotal);
        const currentItotal = parseFloat(currentDesign.iTotal);

        if (currentItotal < minITotal) {
          return currentDesign;
        } else {
          return designWithMinItotal;
        }
      }
    );
    smallestDesign.floor = floor;
    return smallestDesign;
  };

  if (isLoading) {
    return <Spin size="large" tip="Optimizing .." />;
  }

  const handleNext = () => {
    carouselRef.current.next();    
  };
  const handlePrevious = () => {
    carouselRef.current.prev();    
  };

  return (
    <div>
      {showTable && (
        <div>
          <div>
            <Title level={5} style={{ textAlign: "left" }}>
              Smallest design from floor one
            </Title>
            <br />
            <TempTable dataSource={[optimalFloorOne]} />
          </div>
          <div>
            <Title level={5} style={{ textAlign: "left" }}>
              Temp table 1
            </Title>
            <br />
            <Carousel ref={carouselRef}>
              {temp1.map((obj, index) => (
                <div>
                  <TempTable dataSource={obj} />
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <Title level={5} style={{ textAlign: "left" }}>
              Temp table 2
            </Title>
            <br />
            <Carousel>
              {temp2.map((obj, index) => (
                <div>
                  <TempTable dataSource={obj} />
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <Title level={5} style={{ textAlign: "left" }}>
              Optimized design to use fixed column geometries across every floor
              in the building
            </Title>
            <br />
            <OptimizedSummary dataSource={optimalDesigns} />
          </div>
          <div>
            <Title level={5} style={{ textAlign: "left" }}>
              Optimized design to use fixed mixed design
            </Title>
            <br />
            <OptimizedSummary dataSource={constantConcreteOptimization} />
          </div>
        </div>
      )}
    </div>
  );
};

export default FixedColumns;

