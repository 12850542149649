import React from "react";
import { Typography } from "antd";
const { Title } = Typography;


const Carbo2oadTitle = ({color, fontSize}) => {
    const colors = {
        color: color       
    }
    const size = {
        fontSize: fontSize
    }
    return (
      <div>
        <Title style={{fontFamily: "Bauhaus93"}}>
          <span style={colors}>Carboload</span>
        </Title>
      </div>
    );
  };

  export default Carbo2oadTitle;