import { Form, Checkbox } from "antd";
import {React, useEffect} from "react";
import useFormContext from "../../../hooks/useFormContext";
const FloorPlanInfoPart3 = () => {
  const [form] = Form.useForm();
  const {setShapes, shapes} = useFormContext();

  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setShapes(checkedValues);
  };

  useEffect(() => {
    form.setFieldsValue({ geometry: shapes });
  }, [shapes, form]);

  const plainOptions = ['Square', 'Circular', 'Rectangular'];

  return (
    <Form form={form}  style={{ margin: 10 }}>
      <Form.Item
        name="geometry"
        label="Select column geometries (all that apply) to be considered in the optimization task"
      >
        <Checkbox.Group options={plainOptions} value={shapes} onChange={onChange} />
      </Form.Item>
    </Form>
  );
};

export default FloorPlanInfoPart3;
