import { useState } from "react";
import { Table } from "antd";
const MaterialResponseTable = ({ materials, setSelectedMaterials }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selected row keys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);

    const selectedRows = materials.filter((material) =>
      newSelectedRowKeys.includes(material.id)
    );
    setSelectedMaterials(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Compressive Strength (PSI)",
      dataIndex: "compressiveStrength",
      key: "compressiveStrength",
    },
    {
      title: (
        <span>
          GWP (Kg Co<sub>2</sub>-eq / yd<sup>3</sup>)
        </span>
      ),
      dataIndex: "gwp",
      key: "gwp",
    },
    {
      title: "Category",
      dataIndex: "materialCategory",
      key: "materialCategory",
    },
    {
      title: "Approximate Cost (USD / yd3)",
      dataIndex: "cost",
      key: "cost",
    },
  ];
  return (
    <div style={{ margin: 10 }}>
      <Table
        rowKey={(record) => record.id}
        rowSelection={rowSelection}
        dataSource={materials}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default MaterialResponseTable;
