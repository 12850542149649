import PersonComponent from "../MiscComponents/PersonComponent";
import zachry from "../../assets/persons/Zachary-Grasley.jpg";
import kinsey from  "../../assets/persons/kinsey.jpg";
import cesario from "../../assets/persons/cesario.jpeg"
const TeamPage = () => {
    return (
        <div>
            <PersonComponent personName={"Dr Cesario Tavares"} description = {"Founder \n\n Senior Materials Engineer at ICON"} imageUrl={cesario}/>
            <PersonComponent personName={"Dr Zachary Grasley"} description = {"Co-Founder \n\n Department Head of Civil & Env. Engineering at Texas A&M University"} imageUrl={zachry}/>
            <PersonComponent personName={"Dr Kinsey Skillen"} description = {"Co-Founder \n\n Assistant Professor - Civil & Env. Engineering at Texas A&M University"} imageUrl={kinsey}/>
            
        </div>
    );
}

export default TeamPage;