import React, { useState, useRef } from "react";
import { Carousel, Typography } from "antd";
import useFormContext from "../../../hooks/useFormContext";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import Questionnaire from "./Questionnaire";
const { Title } = Typography;
const QuestionsPage = () => {
  const carouselRef = useRef();
  const { designGroups } = useFormContext();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const handleNext = () => {
    carouselRef.current.next();
    setCurrentGroupIndex(
      (currentGroupIndex + 1 + designGroups.length) % designGroups.length
    );
  };
  const handlePrevious = () => {
    carouselRef.current.prev();
    setCurrentGroupIndex(
      (currentGroupIndex - 1 + designGroups.length) % designGroups.length
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handlePrevious}
        />
        <Title
          level={4}
          style={{ marginLeft: 10, marginRight: 10, paddingBottom: 15 }}
        >
          {" "}
          Design Group {designGroups[currentGroupIndex].designGroup}
        </Title>
        <RightCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handleNext}
        />
      </div>
      <Carousel ref={carouselRef}>
        {designGroups.map((group, index) => (
          <div>
            <Questionnaire designGroup = {designGroups[index].designGroup} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default QuestionsPage;
