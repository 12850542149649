import { Button, Form, Input, Typography, Spin, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import Errors from "./Constants/Errors";

const { Text, Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Login = () => {
  const { user, setUser } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const signIn = async (username, password) => {
    try {
      setLoading(true);
      const user = await Auth.signIn(username, password);
      setUser(user);
      setError(false);
      console.log("User logged in is ", user.attributes.email);
    } catch (error) {
      setError(true);
      if (error.code === "NotAuthorizedException") {
        setErrorMessage(Errors.WRONG_PASSWORD);
      } else if (error.code === "UserNotConfirmedException") {
        setErrorMessage(Errors.USER_NOT_VERIFIED);
      }

      console.log("Something went wrong");
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("user from context is ", user);
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const onFinish = async (values) => {
    const { email, password } = values;

    await signIn(email, password);
  };

  const goToSignUp = () => {
    navigate("/signup");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <Title underline level={2}>
        Login
      </Title>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
          margin: 5,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: Errors.EMAIL_NOT_VALID,
            },
            {
              required: true,
              message: Errors.EMAIL_NEEDED,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: Errors.PASSWORD_NEEDED,
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <div>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
            {loading && <Spin />}
            <div style={{ marginTop: 10 }}>
              Dont have an account ?{" "}
              <Button type="link" onClick={goToSignUp}>
                Sign Up
              </Button>{" "}
            </div>
            <div style={{ marginTop: 10 }}>
              {error && <Text type="danger">{errorMessage}</Text>}
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Login;
