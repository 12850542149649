import { Card, Space } from 'antd';
import React from 'react';
const MyProjects = () => (
  <Space direction="horizontal" size={16} style={{margin: 15}}>
    <Card
      title="Project 1"
      extra={<a href="#">More</a>}
      style={{
        width: 300,
      }}
    >
      <p>Carbon Emissions: xx %</p>

    </Card>
    <Card
      title="Project 2"
      extra={<a href="#">More</a>}
      style={{
        width: 300,
      }}
    >
   <p>Carbon Emissions: yy %</p>
    </Card>
  </Space>
);
export default MyProjects;