import { Form, Table, Select, Row, Col } from "antd";
import { React, useState, useEffect } from "react";
import useFormContext from "../../../hooks/useFormContext";
import defaultValues from "../../Constants/DefaultValues";

const RebarInput = () => {
  const [form] = Form.useForm();
  
  // Get values from the context
  const { rebar, setRebar } = useFormContext();
  
  // Set the initial size: if `rebar` exists (user selected it before), use that; otherwise, use the first item
  const defaultSize = rebar?.size || Object.keys(defaultValues.rebarInfo)[0]; 
  const [selectedSize, setSelectedSize] = useState(defaultSize);
  const [rebarSelected, setRebarSelected] = useState(defaultValues.rebarInfo[defaultSize]);

  const handleSizeChange = (value) => {
    setSelectedSize(value);
    setRebarSelected(defaultValues.rebarInfo[value]);
    setRebar(defaultValues.rebarInfo[value]); // Persist the selection in the context
  };

  useEffect(() => {
    // Set the form value
    form.setFieldsValue({ size: selectedSize });

    // If rebar isn't already in the context, set it with the default value
    if (!rebar || !rebar.size) {
      setRebar(defaultValues.rebarInfo[selectedSize]);
    }

    setRebarSelected(defaultValues.rebarInfo[selectedSize]);
  }, [selectedSize, form, rebar, setRebar]);

  const rebarColumns = [
    {
      title: "Size (mm)",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Diameter",
      dataIndex: "diameter",
      key: "diameter",
    },
    { title: "Area (in2)", dataIndex: "area", key: "area" },
  ];

  return (
    <div>
      <Row>
        <Col span={12}>
          <Form form={form} style={{ margin: 10 }}>
            <Form.Item name="size" label="Size">
              <Select
                onChange={handleSizeChange}
                value={selectedSize} // Bind the selected value to the Select input
                placeholder="Select Size (mm)"
              >
                {Object.keys(defaultValues.rebarInfo).map((key) => (
                  <Select.Option key={key} value={key}>
                    {key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          {rebarSelected && (
            <Table
              style={{ marginTop: "10px" }}
              dataSource={[rebarSelected]}
              columns={rebarColumns}
              pagination={false}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RebarInput;
