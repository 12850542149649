import { Table, Carousel, Button } from "antd";
import useFormContext from "../../hooks/useFormContext";
import columnHeadings from "../Constants/ColumnHeadings";
import { useRef, useState } from "react";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const { Title } = Typography;
const FullMatrix = () => {
  const { designGroups } = useFormContext();
  const carouselRef = useRef();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const handleNext = () => {
    carouselRef.current.next();
    setCurrentGroupIndex(
      (currentGroupIndex + 1 + designGroups.length) % designGroups.length
    );
  };
  const handlePrevious = () => {
    carouselRef.current.prev();
    setCurrentGroupIndex(
      (currentGroupIndex - 1 + designGroups.length) % designGroups.length
    );
  };
  const columns = [
    {
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    },
    columnHeadings.concreteCost,
    columnHeadings.concreteGwp,
    {
        title: "Compressive Strength (MPa)",
        dataIndex: "compressiveStrength",
        key: "compressiveStrength",
      },
    {
      title: "Column Description",
      dataIndex: "description",
      key: "description",
    },
    {
        title: "aGlimit (m2)",
        dataIndex: "aGlimit",
        key: "aGlimit",
      },
      {
        title: "aGmin (m2)",
        dataIndex: "aGmin",
        key: "aGmin",
      },
      {
        title: "aGlong (m2)",
        dataIndex: "aGlong",
        key: "aGlong",
      },
      {
        title: "aGshort (m2)",
        dataIndex: "aGshort",
        key: "aGshort",
      },
      {
        title: "agTrial (m2)",
        dataIndex: "agTrial",
        key: "agTrial",
      },
      {
        title: "columnType",
        dataIndex: "columnType",
        key: "columnType",
      },
      {
        title: "beta",
        dataIndex: "beta",
        key: "beta",
      },
      {
        title: "dTrial (m)",
        dataIndex: "dTrial",
        key: "bedTrialta",
      },
      {
        title: "bTrial (m)",
        dataIndex: "bTrial",
        key: "bTrial",
      },
      {
        title: "hTrial (m)",
        dataIndex: "hTrial",
        key: "hTrial",
      },
      {
        title: "rho",
        dataIndex: "pw",
        key: "pw",
      },
      {
        title: "pu (MN)",
        dataIndex: "pu",
        key: "pu",
      },
      {
        title: "du (MN)",
        dataIndex: "du",
        key: "du",
      },
      

    columnHeadings.iC,
    columnHeadings.iSw,
    columnHeadings.iTotal,
    columnHeadings.dollarC,
    columnHeadings.dollarSw,
    columnHeadings.dollarFw,
    columnHeadings.dollarTotal
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handlePrevious}
        />
        <Title
          level={4}
          style={{ marginLeft: 10, marginRight: 10, paddingBottom: 15 }}
        >
          {" "}
          Design Group {designGroups[currentGroupIndex].designGroup}
        </Title>
        <RightCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handleNext}
        />
      </div>
      <Carousel ref={carouselRef}>
        {designGroups.map((designGroup, index) => (
          <div>
            <Table
              dataSource={designGroup.combinations}
              columns={columns}
              pagination={true}
              scroll={{ x: 'max-content' }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default FullMatrix;

/**
 * 
 * * aGlimit: "0.407"aGlong: "0.330"aGmin: "0.065"aGshort: "0.489"agTrial: "0.489"bTrial: 0.7beta: 1beta2: 1.1785722796342926columnShape: "Rectangular"columnType: "Short"compressiveStrength: 27.57897930197603concreteCost: "141.5"concreteGwp: "324.4"dTrial: NaNdescription: "28in x 28in Short rectangular column w/ tied reinf & pw = 1%"dollarC: "278.25"dollarFw: "11.35"dollarSw: "85.47"dollarTotal: "375.07"draftArea: 0.3121516164994426du: 5.599998220711805ec: 24856hTrial: 0.7iC: "637.91"iSw: "119.69"iTotal: "757.60"id: 1product: 1pu: 6.6000026689322935pw: 1shape: "Square"shearReinforcement: "Ties"
* 		aGlimit: "0.407"aGlong: "0.330"aGmin: "0.065"aGshort: "0.489"agTrial: "0.489"bTrial: 0.7beta: 1beta2: 1.1785722796342926columnShape: "Rectangular"columnType: "Short"compressiveStrength: 27.57897930197603concreteCost: "141.5"concreteGwp: "324.4"dTrial: NaNdescription: "28in x 28in Short rectangular column w/ tied reinf & pw = 1%"dollarC: "278.25"dollarFw: "11.35"dollarSw: "85.47"dollarTotal: "375.07"draftArea: 0.3121516164994426du: 5.599998220711805ec: 24856hTrial: 0.7iC: "637.91"iSw: "119.69"iTotal: "757.60"id: 1product: 1pu: 6.6000026689322935pw: 1shape: "Square"shearReinforcement: "Ties"


 */