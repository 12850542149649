// this is the one that will be used with api
import Errors from "../../Constants/Errors";
import { useState } from "react";
import MaterialSearchInputForm from "./MaterialSearchInputForm";
import { Typography, Button, Spin, Alert, Empty } from "antd";
import MaterialResponseTable from "./MaterialResponseTable";
import {
  modifyResponse,
} from "../../../utils/materialSearchUtil";
import { fetchMaterials } from "../../../services/material-api";
import useFormContext from "../../../hooks/useFormContext";
const { Title, Text } = Typography;
const MaterialInput = () => {
  //const [searchResponse, setSearchResponse] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [materialFilter, setMaterialFilter] = useState(null);
  const [isEndOfResults, setIsEndOfResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [id, setId] = useState(1);
  const [searchStatus, setSearchStatus] = useState("INITIAL");
  const { selectedMaterials, setSelectedMaterials, searchResponse, setSearchResponse } = useFormContext();

  const fetchMoreMaterials = async (pageNumber) => {
    const mf = materialFilter;
    setLoading(true);
    try {
      const newMaterials = await fetchMaterials(mf, pageNumber);
      if (!newMaterials) {
        setIsEndOfResults(true); // Set end of results if no new data
      } else {
        const modifiedResponse = modifyResponse(newMaterials, id);
        setSearchResponse((prev) => [
          ...prev,
          ...modifiedResponse,
        ]);
        setError(false);
        setIsEndOfResults(false);
        setCurrentPage(pageNumber);
        setId(id + modifiedResponse.length);
      }
    } catch (error) {
      console.log("Error is this ", error.message);
      switch (error.message) {
        case Errors.HTTP_404:
          setIsEndOfResults(true); // Set end of results if 404 error
          setError(false);
          break;
        default:
          setError(true);
          setIsEndOfResults(false);
      }
    }
    setLoading(false);
  };

  const loadMore = () => {
    if (!isEndOfResults) {
      fetchMoreMaterials(currentPage + 1);
    }
  };

  const searchForm = (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 10,
        }}
      >
        <Title level={3}>Select Materials</Title>
      </div>

      <MaterialSearchInputForm
        setId={setId}
        setIsEndOfResults={setIsEndOfResults}
        materialFilter={setMaterialFilter}
        searchResponse={setSearchResponse}
        setSearchStatus={setSearchStatus}
      />
      {error && (
        <Text type="danger">Something went wrong while loading data</Text>
      )}
    </div>
  );

  const alert = (
    <Alert
      message="Error"
      description="Something went wrong, try again"
      type="error"
      closable
      
    />
  );

  const AlertMessage = ({ message }) => {
    return (
      <Alert
        message="Error"
        description={message || "Something went wrong, try again"}
        type="error"
        closable
      />
    );
  };
  

  return (
    <div>
      {searchForm}

      {(selectedMaterials.length != 0 || searchResponse.length != 0) && (
        <MaterialResponseTable
          materials={searchResponse}
          setSelectedMaterials={setSelectedMaterials}
        />
      )}
      {searchStatus === "EMPTY" && (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 60,
          }}
          description={
            <span>
              No results found with this search query. Try with different
              states.
            </span>
          }
        ></Empty>
      )}
      {isEndOfResults ? (
        <p>End of search results</p>
      ) : (
        searchResponse.length != 0 && (
          <Button onClick={loadMore} loading = {loading}>Load More</Button>
        )
      )}
      
      {searchStatus === "ERROR" && (<AlertMessage message = "Something went wrong, try again" />)}

    </div>
  );
};

export default MaterialInput;
