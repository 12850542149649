import { Button, Descriptions, Radio } from "antd";
const MixInformationModal = ({ mixObject }) => {
  const borderedItems = [
    {
      key: "1",
      label: "Product ID",
      children: mixObject.id,
    },
    {
      key: "2",
      label: "Manufacturer",
      children: mixObject.manufacturer,
    },
    {
      key: "3",
      label: "Plant",
      children: mixObject.plant,
    },
    {
      key: "4",
      label: "Description",
      children: mixObject.description,
    },
    {
      key: "5",
      label: "Compressive Strength",
      children: mixObject.compressiveStrength + " psi",
    },
    {
      key: "6",
      label: "GWP",
      children: <div>{mixObject.gwp} <span> kg.Co2-eq/yd<sup>3</sup></span></div>,
    },
    {
      key: "6",
      label: "Cost",
      children: <div>{mixObject.cost} <span>USD/yd<sup>3</sup></span></div>,
    },
    {
      key: "6",
      label: "Material Category",
      children: mixObject.materialCategory,
    },
  ];

  //   {
  //     subcategory: "UHPC 1",
  //     manufacturer: "Graniteock",
  //     plant: "Del monte boulevard",
  //     id: 1,
  //     description: "8 sack, 3000 psi",
  //     compressiveStrength: 22930,
  //     gwp: 1675,
  //     cost: 1012,
  //     materialCategory: "UHPC",
  //   },

  return (
    <div>
      <Descriptions bordered size="small">
        {borderedItems.map((item) => (
          <Descriptions.Item key={item.label} label={item.label}>
            {item.children}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  );
};

export default MixInformationModal;
