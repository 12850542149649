import { Button, Form, Input, Spin, Typography } from "antd";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ConfirmEmailPage = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const username = location.state?.username;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Username from sign-up page:", username);
  }, [username]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const res = await Auth.confirmSignUp(username, values.verificationCode);
      console.log("Success ", res);
      setError(false);
      navigate('/login');
    } catch (error) {
      if (error.code === "CodeMismatchException") {
        setError(true);
      }
      console.log("Something went wrong, need to catch appropriate error ");
    }
    setLoading(false);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Title level={2}>Confirm Email</Title>
      <Text>Check your email for verification code</Text>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="verificationCode"
          label="Verification Code"
          rules={[
            {
              required: true,
              message: "Please input verification number",
            },
          ]}
        >
          <Input
            style={{
              width: "80%",
              marginLeft: 20
            }}
          />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Verify Email
          </Button>
          {loading && <Spin />}
        </Form.Item>
      </Form>
      {error && (
        <Text type="danger">Code doesn't match the one sent to email</Text>
      )}
    </div>
  );
};
export default ConfirmEmailPage;
