import { Radio, Space } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import useFormContext from "../../../hooks/useFormContext";
import defaultValues from "../../Constants/DefaultValues";
const OptionSelector = () => {
  const { materialsInputPereference, setMaterialsInputPereference, setSelectedMaterials } =
    useFormContext();
  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setMaterialsInputPereference(e.target.value);

  };

  useEffect(() => {

    if (materialsInputPereference == 2) {
      console.log("Adding default values");
      setSelectedMaterials(defaultValues.concretes);
    }

  }, [materialsInputPereference])

  return (
    <div style={{ textAlign: "left", margin: 10 }}>
      <Title level={3}>How do you want to select materials ? </Title>
      <Radio.Group onChange={onChange} value={materialsInputPereference}>
        <Space direction="vertical">
          <Radio value={1}>Search from EC3 database</Radio>
          <Radio value={2}>Add materials from research paper</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export default OptionSelector;
