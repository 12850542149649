import { fetchMaterials } from "../services/material-api";

export function buildMf(jurisdiction, dateValidityEnds) {
  return `!EC3 search("ReadyMix") WHERE jurisdiction: IN(${jurisdiction}) AND epd__date_validity_ends: > "${dateValidityEnds}" 
    AND epd_types: IN("Product EPDs", "Industry EPDs") !pragma eMF("2.0/1"), lcia("TRACI 2.1")`;
}

export function getTodayDateString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function buildJurisdiction(states) {
  const country = "US";
  const jurisdictionList = [];
  states.forEach((state) => {
    jurisdictionList.push(`"${country}-${state}"`);
  });
  return jurisdictionList;
}

const calculateCost = (compressiveStrength) => {
  return Math.round(120 * Math.exp(0.000108 * compressiveStrength));
}

export function modifyResponse(response, id) {
  return response
    .map((ele) => {
      if (!ele.concrete_compressive_strength_28d || !ele.gwp) {
        return null; // Skip if either value is blank or null
      }

      // Extract and convert compressiveStrength
      let [compressiveStrengthValue, strengthUnit] =
        ele.concrete_compressive_strength_28d.split(" ");
      compressiveStrengthValue = parseFloat(compressiveStrengthValue);
      if (strengthUnit === "MPa") {
        compressiveStrengthValue *= 145.038; // Convert Mpa to psi
      }
      compressiveStrengthValue = Math.round(compressiveStrengthValue);
      // Extract and convert gwp
      let [gwpValue] = ele.gwp.split(" ");
      gwpValue = parseFloat(gwpValue) * 1.30795; // Convert from kgCO2e/m³ to kgCO2e/yd³
      gwpValue = Math.round(gwpValue);

      let materialCategory = '';

      if (compressiveStrengthValue > 17400) {
        materialCategory = "UHPC";
      } else if (
        compressiveStrengthValue > 7250 &&
        compressiveStrengthValue <= 17400
      ) {
        materialCategory = "HPC";
      } else if (
        compressiveStrengthValue > 2900 &&
        compressiveStrengthValue <= 7250
      ) {
        materialCategory = "NSC";
      } else {
        materialCategory = "PLC";
      }

      // Create the modified object
      const modifiedObject = {
        manufacturer: ele.manufacturer.name ? ele.manufacturer.name : "",
        plant: ele.plant_or_group ? ele.plant_or_group.name : "",
        name: ele.name,
        description: ele.description,
        compressiveStrength: compressiveStrengthValue,
        gwp: gwpValue,
        id: id++,
        materialCategory: materialCategory,
        cost: calculateCost(compressiveStrengthValue)
      };

      return modifiedObject;
    })
    .filter((item) => item !== null); // Filter out null objects
}

export async function fetchFromApi(mf, pageNumber) {
  try {
    let response = await fetchMaterials(mf, pageNumber);
    console.log(response);
    return response;
  } catch (error) {
    console.log("something went wrong", error);
  }
}
