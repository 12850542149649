// get combination with least iTotal
export function getMinFromBuilding(combinations) {
  const smallestDesign = combinations.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.iTotal);
      const currentItotal = parseFloat(currentDesign.iTotal);

      if (currentItotal < minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );
  return smallestDesign;
}

export function getCombinationWithMinimumCost(buildingValues) {
  const answer = buildingValues.reduce(
    (designWithMinDollarTotal, currentDesign) => {
      const minDollarTotal = parseFloat(designWithMinDollarTotal.dollarTotal);
      const currentDollartotal = parseFloat(currentDesign.dollarTotal);

      if (currentDollartotal < minDollarTotal) {
        return currentDesign;
      } else {
        return designWithMinDollarTotal;
      }
    }
  );
  return answer;
}

export function getCombinationWithMaximumCost(buildingValues) {

  const answer = buildingValues.reduce(
    (designWithMinDollarTotal, currentDesign) => {
      const minDollarTotal = parseFloat(designWithMinDollarTotal.dollarTotal);
      const currentDollartotal = parseFloat(currentDesign.dollarTotal);

      if (currentDollartotal > minDollarTotal) {
        return currentDesign;
      } else {
        return designWithMinDollarTotal;
      }
    }
  );
  return answer;
  
}

// gets design with maz iTotal
export function getCombinationWithMaxItotal(combinations) {
  const smallestDesign = combinations.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.iTotal);
      const currentItotal = parseFloat(currentDesign.iTotal);

      if (currentItotal > minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );
  return smallestDesign;
}


