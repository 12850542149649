import { Table, Typography, Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import useFormContext from "../../hooks/useFormContext";
import MixInformationModal from "./MixInformationModal";
const { Title } = Typography;
const OptimizedSummary = ({ dataSource }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const {
    concreteInfo,
  } = useFormContext();


  const getDesignGroupType = (name) => {
    if (name.startsWith("Int")) return "Interior";
    if (name.startsWith("Ext")) return "Exterior";
    if (name.startsWith("Cor")) return "Corner";
    return "Unknown";
  };

  const designGroupColumns = dataSource[0]?.designGroups?.map((group, index) => ({
    title: `${getDesignGroupType(group.name)}`,
    dataIndex: ["designGroups", index, "description"],
    key: `designGroup${index}`,
  })) || [];

  const columns = [
    {
      title: "Floor Group",
      dataIndex: "floor",
      key: "floor",
      fixed: "left",
      width: 300,
    },
    {
      title: "Column Types",
      children: designGroupColumns,
    },
    {
      title: "Mix Design to use",
      dataIndex: "product",
      key: "product",
      fixed: "right",
      width: 300,
    },
    {
      title: 'Description',
      key: 'description',
      fixed: "right",
      render: (record) => (
        <Button onClick={() => openModal(record.product)}>Details</Button>
      ),
    },
  ];

  const openModal = (mixDesign) => {
    const objectToShowInsideModal = concreteInfo.find(
      (concrete) => concrete.id === mixDesign
    );
    setModalData(objectToShowInsideModal);
    setIsModalOpen(true);
  }

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false}/>
      <Modal
      title="Product Details"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      width="150vh"
      footer={[
        <Button key="back" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>,
      ]}
    >
      <MixInformationModal mixObject = {modalData}/>
      {/* <p>{modalData && JSON.stringify(modalData)}</p> */}
    </Modal>
    </div>
  );
};

export default OptimizedSummary;
