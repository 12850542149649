import React, { useState, useEffect } from "react";
import { InputNumber, Form, Button, message, Checkbox, Typography, Table } from "antd";
import useFormContext from  "../../../hooks/useFormContext";
import defaultValues from "../../Constants/DefaultValues";
const {Title} = Typography;
const VerticalPartitionForm = ({isGroupFilled}) => {
  const [numFloors, setNumFloors] = useState(0);
  const [numGroups, setNumGroups] = useState(0);
  const [ verticalPartition, setVerticalPartition ] = useState([]); // {group: "", start: x, end: y}
  const { floorPlanInfo, setFloorPlanInfo, setDesignGroups, rebar } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  //TODO: remove the hardcode once default values are removed
  const useDefaultValue = () => {
    setDesignGroups(defaultValues.designGroups);
    setFloorPlanInfo([{group: "A", start: 1, end:2},{group:"B", start:3, end:12}])
    //isGroupFilled();
    success();
  };

  const handleNumFloorsChange = (value) => {
    setNumFloors(value);
  };

  const handleNumGroupsChange = (value) => {
    setNumGroups(value);

    // Generate initial floor group data
    const newFloorGroups = Array.from({ length: value }, (_, index) => ({
      group: String.fromCharCode(65 + index),
      start: "",
      end: "",
    }));
    setVerticalPartition(newFloorGroups);
  };

  const handleFloorGroupChange = (index, key, value) => {
    const updatedGroups = [...verticalPartition];
    updatedGroups[index][key] = value;
    setVerticalPartition(updatedGroups);
    
  };

  const onFinish = () => {
    setFloorPlanInfo(verticalPartition);
    success();  
    isGroupFilled();
  };

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Groups Assigned Successfully',
    });
  };

  const columns = [
    {
      title: "Group",
      dataIndex: "group",
      key: "group"
    },
    {
      title: "Floor Start",
      dataIndex: "start",
      key: "start"
    },
    {
      title: "Floor End",
      dataIndex: "end",
      key: "end"
    }

  ]

  useEffect(() => {
    console.log("Floor Plan master object after state update", floorPlanInfo);
    console.log("Rebar is ", rebar);
  }, [floorPlanInfo]);

  return (
   <div style={{margin: 10}}>
    {contextHolder}
    <Title level = {3} style={{textAlign: "left"}}>Vertical Partition</Title>
     <Form form={form} onFinish={onFinish} {...formItemLayout}>
      <Form.Item label="Total Number of Floors in the building">
        <InputNumber value={numFloors} onChange={handleNumFloorsChange} />
      </Form.Item>
      <Form.Item label="Number of vertical partitions for design (group of floors)">
        <InputNumber value={numGroups} onChange={handleNumGroupsChange} />
      </Form.Item>
      {verticalPartition.map((group, index) => (
        <div key={index}>
          <Form.Item label={`Group ${group.group}:`}>
            Floors {"  "}
            <InputNumber
              placeholder="Start"
              value={group.start}
              onChange={(value) =>
                handleFloorGroupChange(index, "start", value)
              }
            />
            <span style={{ margin: "0 8px" }}>to</span>
            <InputNumber
              placeholder="End"
              value={group.end}
              onChange={(value) => handleFloorGroupChange(index, "end", value)}
            />
          </Form.Item>
        </div>
      ))}
      <Form.Item>
        <Button  style={{ textAlign: 'center' }} htmlType="submit">Assign Groups</Button>
      </Form.Item>
    </Form>
    <div>
        <Checkbox onChange={useDefaultValue}>Use default value</Checkbox>
      </div>
      {floorPlanInfo && (
        <Table columns={columns} dataSource={floorPlanInfo}/>
      )}

   </div>
  );
};

export default VerticalPartitionForm;
