import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import useFormContext from "../../../hooks/useFormContext";
import {
  getCombinationWithMaxItotal,
  getCombinationWithMaximumCost,
  getCombinationWithMinimumCost,
  getMinFromBuilding,
} from "../../../utils/buildingCalculations";
import { Spin } from "antd";
// Register the chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartPlot = () => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});
  const { buildingValues } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [additionalData, setAdditionalData] = useState([]);


  useEffect(() => {
    setLoading(true);

    const bestCost = getCombinationWithMinimumCost(buildingValues);
    const worstCost = getCombinationWithMaximumCost(buildingValues);
    const bestEmission = getMinFromBuilding(buildingValues);
    const worstEmission = getCombinationWithMaxItotal(buildingValues);

    const additionalInfo = [
      { product: bestCost.product, label: "Lowest Cost" },
      { product: worstCost.product, label: "Highest Cost" },
      { product: bestEmission.product, label: "Lowest Emission" },
      { product: worstEmission.product, label: "Highest Emission" },
    ];
  
    setAdditionalData(additionalInfo);
    setChartData({
      labels: ["Lowest Cost", "Highest Cost", "Lowest Emission", "Highest Emission"],
      datasets: [
        {
          label: "Cost",
          data: [
            bestCost.dollarTotal,
            worstCost.dollarTotal,
            bestEmission.dollarTotal,
            worstEmission.dollarTotal,
          ],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "y-axis-cost",
        },
        {
          label: " CO2 emission",
          data: [
            bestCost.iTotal,
            worstCost.iTotal,
            bestEmission.iTotal,
            worstEmission.iTotal,
          ],
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          yAxisID: "y-axis-emission",
        },
      ],
    });

    setChartOptions({
      scales: {
        "y-axis-cost": {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-cost",
          stacked: true,
          min: 0,
          max: Math.ceil(worstCost.dollarTotal) + 10, // Set to the maximum cost value
          title: {
            display: true,
            text: "Cost (x1000 USD)",
          },
        },
        "y-axis-emission": {
          type: "linear",
          display: true,
          position: "right",
          id: "y-axis-emission",
          stacked: true,
          min: Math.floor(bestEmission.iTotal) - 10, // Set to the minimum emission value
          max: Math.ceil(worstEmission.iTotal) + 10, // Set to the maximum emission value
          grid: {
            drawOnChartArea: false, // to only show the grid for the left axis
          },
          title: {
            display: true,
            text: "CO2 Emission (x 1000 kg.CO2-eq)",
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(context) {
              const index = context.dataIndex;
              const obj = additionalInfo[index];
              if (obj) {
                return `Mix #: ${obj.product}\nValue: ${context.formattedValue}`;
              }
              return `Value: ${context.formattedValue}`; // Fallback in case obj is undefined
            }
          }
        },
        title: {
          display: true,
          text: "Impact values per building"
        }
      },
    });

    setLoading(false);
  }, []);

  if (loading) {
    return <Spin />;
  }

  return <Bar data={chartData} options={chartOptions} />;
};

export default BarChartPlot;
