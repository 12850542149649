const Errors =  {
    EMAIL_EXISTS : "This Email already exists",
    WRONG_PASSWORD: "The password is wrong",
    USER_NOT_VERIFIED: "The user is not confirmed",
    CONFIRM_PASSWORD: "Please confirm your password!",
    PASSWORD_DOES_NOT_MATCH: "The new password that you entered does not match!",
    EMAIL_NOT_VALID: "The input is not valid E-mail!",
    EMAIL_NEEDED: "Please input your E-mail!",
    PASSWORD_NEEDED: "Please input your password!",

    //----------component specific---------------------
    STEEL_REBAR_MISSING: "Longitudinal steel rebar missing",
    //----http error-----------
    HTTP_404: "Not Found",
    HTTP_401: "Unauthorized",
    HTTP_400: "Bad Request",
    HTTP_500: "Server Error"

}

export default Errors