import { useState } from "react";
import {
  Table,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Switch,
  Checkbox,
} from "antd";

import useFormContext from "../../../hooks/useFormContext";
import defaultValues from "../../Constants/DefaultValues";
const SteelReinforcementInput = () => {
  const { row, setRow, setShearReinforcement, shearReinforcement, setSteel } =
    useFormContext();
  const [form] = Form.useForm();
  const [dataInSI, setDataInSI] = useState([]);
  const [isSI, setIsSI] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [rebarSelected, setRebarSelected] = useState();
  const [selectedSize, setSelectedSize] = useState(null);
  const [costPerTon, setCostPerTon] = useState(0);

  const onFinish = (values) => {
    setShearReinforcement([...shearReinforcement, values.subcategory]);
    values.yieldStrength = calculateYieldStrength(values.steelGrade);
    if (values.subcategory === "Longitudinal steel rebars") {
      // cost and yield strength of steel to be used later
      const steelInfo = {
        yieldStrength: values.yieldStrength,
        cost: values.cost,
        gwp: values.gwp,
      };
      setSteel(steelInfo);
    }
    console.log(calculateYieldStrength(values.steelGrade));
    setRow([...row, values]);
    copyDataInSI(values);
    console.log(values);
    form.resetFields();
    console.log("Shear values are ", shearReinforcement);
  };

  const copyDataInSI = (values) => {
    const modfieidData = { ...values };

    modfieidData.yieldStrength = Math.ceil(values.yieldStrength * 0.006894); // convert to MPa
    modfieidData.gwp = Math.ceil(values.gwp / 0.764); // convert to m3
    modfieidData.cost = Math.ceil(values.cost / 0.764); // comvert to m3

    setDataInSI([...dataInSI, modfieidData]);
  };

  const calculateYieldStrength = (grade) => {
    if (grade === "Grade 60") {
      return 60000;
    }
    return 40000;
  };

  const rebarColumns = [
    {
      title: "Size (mm)",
      dataIndex: "size",
      key: "size",
    },
    {
      title: "Diameter",
      dataIndex: "diameter",
      key: "diameter",
    },
    { title: "Area (in2)", dataIndex: "area", key: "area" }
  ];

  const useDefaultValue = (e) => {
    console.log(e.target.checked);
    setIsChecked(!isChecked);
    if (e.target.checked) {
      onFinish(defaultValues.steelReinforcement);
    } else {
      setRow([]);
      setSteel();
      setDataInSI([]);
    }
  };

  const handleSizeChange = (value) => {
    setSelectedSize(value);
    setRebarSelected(defaultValues.rebarInfo[value]);
    setCostPerTon(defaultValues.rebarInfo[value].pricePerTonAverage); // Set default cost per ton from the table
  };

  const handleCostPerTonChange = (value) => {
    setCostPerTon(value);
  };

  const input = (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      style={{ maxWidth: 300, marginTop: 20 }}
      initialValues={{
        subcategory: "Shear Reinforcement",
        steelGrade: "Grade 40",
      }}
    >
      <Form.Item name="subcategory" label="Subcategory">
        <Select
          defaultValue="Shear Reinforcement - Ties"
          options={[
            {
              value: "Shear Reinforcement - Ties",
              label: "Shear Reinforcement - Ties",
            },
            {
              value: "Shear Reinforcement - Spirals",
              label: "Shear Reinforcement - Spirals",
            },
            {
              value: "Longitudinal steel rebars",
              label: "Longitudinal steel rebars",
            },
          ]}
        />
      </Form.Item>
      <Form.Item name="manufacturer" label="Manufacturer">
        <Input />
      </Form.Item>
      <Form.Item name="plant" label="Plant">
        <Input />
      </Form.Item>
      <Form.Item name="product" label="Product ID">
        <Input />
      </Form.Item>
      <Form.Item name="steelGrade" label="Steel Grade">
        <Select
          defaultValue="Grade 40"
          options={[
            { value: "Grade 60", label: "Grade 60" },
            {
              value: "Grade 40",
              label: "Grade 40",
            },
          ]}
        />
      </Form.Item>
      <Form.Item name="yieldStrength" label="Yield Strength">
        <Input disabled addonAfter="psi" />
      </Form.Item>
      <Form.Item name="gwp" label="GWP">
        <Input addonAfter="tons.CO2-eq/yd3" />
      </Form.Item>
      <Form.Item name="size" label="Size">
        <Select onChange={handleSizeChange} placeholder="Select Size (mm)">
          {Object.keys(defaultValues.rebarInfo).map((key) => (
            <Select.Option key={key} value={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="costPerTon" label="Cost">
        <Input
          value={costPerTon}
          addonAfter="USD/ton"
          onChange={handleCostPerTonChange}
        />
      </Form.Item>
      <Form.Item name="cost" label="Cost">
        <Input addonAfter="USD/yd3" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </Form.Item>
      <Checkbox onChange={useDefaultValue}>Use default value</Checkbox>
    </Form>
  );

  const handleSwitchChange = () => {
    setIsSI(!isSI);
  };

  const columns = [
    {
      title: "Sub Category",
      dataIndex: "subcategory",
      key: "subcategory",
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
    {
      title: "Plant",
      dataIndex: "plant",
      key: "plant",
    },
    {
      title: "Product ID",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Steel grade",
      dataIndex: "steelGrade",
      key: "steelGrade",
    },
    {
      title: "Yield Strength " + (isSI ? "(MPa)" : "(psi)"),
      dataIndex: "yieldStrength",
      key: "yieldStrength",
    },
    {
      title: isSI ? (
        <span>
          GWP (tons.CO<sub>2</sub> / m<sup>3</sup>)
        </span>
      ) : (
        <span>
          GWP (tons.CO<sub>2</sub> / yd <sup>3</sup>)
        </span>
      ),
      dataIndex: "gwp",
      key: "gwp",
    },
    {
      title: isSI ? (
        <span>
          Cost (USD / m <sup>3</sup>)
        </span>
      ) : (
        <span>
          Cost (USD / yd <sup>3</sup>)
        </span>
      ),
      dataIndex: "cost",
      key: "cost",
    },
  ];

  return (
    <div>
      <Row>
        <Col span={8}>{input}</Col>

        <Col span={12}>
          {rebarSelected && (
            <Table
              style={{ marginTop: "10px" }}
              dataSource={[rebarSelected]}
              columns={rebarColumns}
              pagination={false}
            />
          )}
          {row.length > 0 && (
            <div>
              <Table
                style={{ marginTop: "10px" }}
                dataSource={isSI ? dataInSI : row}
                columns={columns}
                pagination={false}
              />
              Show in SI <Switch onChange={handleSwitchChange} />
            </div>
          )}{" "}
        </Col>
      </Row>
    </div>
  );
};

export default SteelReinforcementInput;
