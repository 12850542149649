import { Carousel, Button, Table } from "antd";
import useFormContext from "../../hooks/useFormContext";
import React, { useState, useRef } from "react";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import columnHeadings from "../Constants/ColumnHeadings";

const { Title } = Typography;

const MatrixByFloor = () => {
  const { floorValues } = useFormContext();
  const carouselRef = useRef();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
 

  const handleNext = () => {
    carouselRef.current.next();
    setCurrentGroupIndex(
      (currentGroupIndex + 1 + floorValues.length) % floorValues.length
    );
  };
  const handlePrevious = () => {
    carouselRef.current.prev();
    setCurrentGroupIndex(
      (currentGroupIndex - 1 + floorValues.length) % floorValues.length
    );
  };

  const getColumns = (obj) => {
    // TODO: column order
    const keys = Object.keys(obj);
    console.log("Keys of floor object ", keys);
    const restrictedProperties = [
      "iC",
      "iSw",
      "iTotal",
      "dollarC",
      "dollarSw",
      "dollarFw",
      "id",
      "dollarTotal",
      "product", 
      "designGroups"
    ];
    const columns = [];
    columns.push({
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    });
    const columnHeadingsForDesignGroups = keys
      .filter((key) => !restrictedProperties.includes(key))
      .map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
      }));
    console.log("This should only have design groups columns ", columnHeadingsForDesignGroups);
    columns.push(
      ...columnHeadingsForDesignGroups,
      columnHeadings.iC,
      columnHeadings.iSw,
      columnHeadings.iTotal,
      columnHeadings.dollarC,
      columnHeadings.dollarSw,
      columnHeadings.dollarFw,
      columnHeadings.dollarTotal
    );

    return columns;
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handlePrevious}
        />
        <Title
          level={4}
          style={{ marginLeft: 10, marginRight: 10, paddingBottom: 15 }}
        >
          {" "}
          Floor {floorValues[currentGroupIndex].floor}
        </Title>
        <RightCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handleNext}
        />
      </div>
      <Carousel ref={carouselRef}>
        {floorValues.map((floor, index) => (
          <div>
            <Table
              dataSource={floor.combinations}
              columns={getColumns(floor.combinations[0])}
              pagination={true}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MatrixByFloor;
