import React from "react";
import { Typography, Image, Row, Col, Button } from "antd";
import CarboloadTitle from "./MiscComponents/CarboloadTitle";
import concrete_wall from "../assets/concrete_wall.jpg";
import { useNavigate } from "react-router-dom";
import concrete_mix from "../assets/concrete_mix.jpg";
import graph from "../assets/graph_2.jpg";
const { Text, Title } = Typography;

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          color: "white",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${concrete_mix})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            filter: "brightness(0.7)", // Apply brightness only to background image
            position: "absolute",
            zIndex: -1, // Place behind content
          }}
        />
        <div
          style={{
            marginTop: 20,
            textAlign: "center",
            flexGrow: 0.5, // Push buttons to the bottom
          }}
        >
          <Title level={1} style={{ color: "white" }}>
            Software for integrated optimization strategies <br />
            to decarbonize concrete building design
          </Title>
        </div>
        <div
          style={{
            marginBottom: 40, // Adjust this for spacing from the bottom
          }}
        >
          <Button size = "large" type="primary" style={{ marginRight: 10 }} onClick={() => navigate("/input")}>Start Project</Button>
          <Button size = "large" ghost>Learn more</Button>
        </div>
      </div>

      <div
        style={{
          margin: "20px",
          marginBottom: "20px",
          flexDirection: "column",
        }}
      >
        <Row
          gutter={24}
          style={{
            marginTop: "20px",
          }}
        >
          <Col span={12}>
            <Image
              src={graph}
              style={{
                height: "100%",
                objectFit: "cover",
                borderRadius: "25px",
              }}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "#f3eeed",
              padding: "20px",
              borderRadius: "25px",
            }}
          >
            <Title level={1} style={{ margin: 0 }}>
             Insights on cost and carbon emissions
            </Title>
            <Text style={{ alignSelf: "flex-start", marginTop: "10px" }}>
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here
            </Text>
          </Col>
        </Row>
        <Row
          gutter={24}
          style={{
            marginTop: "20px",
          }}
        >
          <Col
            span={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "#f3eeed",
              padding: "20px",
              borderRadius: "25px",
            }}
          >
            <Title level={1} style={{ margin: 0 }}>
              Get the best concrete mix suggestions
            </Title>
            <Text style={{ alignSelf: "flex-start", marginTop: "10px" }}>
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here some lorem ipsum here some lorem ipsum here
              some lorem ipsum here
            </Text>
          </Col>
          <Col span={12}>
            <Image
              src={graph}
              style={{
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px",
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
