import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
Amplify.configure({
  Auth: {
    userPoolId: "us-east-2_WURP8XbUf", //UserPool ID
    region: "us-east-2",
    userPoolWebClientId: "2gkh8iuo3daq8qh16sj1gb812q", //WebClientId
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
