import React, { useState } from "react";
import { Form, Select, Button, Spin, Collapse, message, Empty } from "antd";
import usStates from "../../Constants/StateMappings";
import {
  buildJurisdiction,
  getTodayDateString,
  buildMf,
  modifyResponse,
} from "../../../utils/materialSearchUtil";
import { fetchMaterials } from "../../../services/material-api";
const { Option } = Select;

const MaterialSearchInputForm = ({
  setIsEndOfResults,
  materialFilter,
  searchResponse,
  setId,
  setSearchStatus,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const convertStatesToCodes = (states) => {
    const stateCodes = [];
    states.forEach((state) => {
      stateCodes.push(usStates[state]);
    });
    return stateCodes;
  };

  if (loading) {
    return <Spin />;
  }

  const emptyForm = () => {
    messageApi.open({
      type: 'error',
      content: 'Select country and state to perform search',
    });
  };

  const onSearch = async (values) => {
    let id = 1;
    setSearchStatus("INITIAL");
    setIsEndOfResults(false);
    console.log("Received values of form: ", values);

    // check if form is filled or not
    if (!values.country || !values.state) {
      emptyForm();
      return;
    }

    const stateCodes = convertStatesToCodes(values.state);
    const jurisdictionList = buildJurisdiction(stateCodes);
    const date = getTodayDateString();
    const mf = buildMf(jurisdictionList, date);
    materialFilter(mf);

    // make the api call
    setLoading(true);
    try {
      const response = await fetchMaterials(mf, 1);
      if (response.length == 0) {
        setSearchStatus("EMPTY");
      }
      const modifiedResponse = modifyResponse(response, id);
      searchResponse(modifiedResponse);
      setId(modifiedResponse.length + 1);
    } catch (error) {
      console.log(error);
      setSearchStatus("ERROR");
    } finally {
      setLoading(false);
    }
  };

  const searchForm = (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      style={{ maxWidth: 300, marginTop: 20 }}
      onFinish={onSearch}
    >
      <Form.Item name="country" label="Country">
        <Select>
          <Option value="USA">USA</Option>
        </Select>
      </Form.Item>
      <Form.Item name="state" label="State">
        <Select mode="multiple" placeholder="Select states">
          {Object.keys(usStates).map((state) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">Search</Button>
      </Form.Item>
    </Form>
  );

  const items = [
    {
      key: "1",
      label: "Search for materials",
      children: <>{searchForm}</>,
    },
  ];

  return (
    <div>
       {contextHolder}
      <Collapse
        style={{ margin: 10, textAlign: "left" }}
        items={items}
        defaultActiveKey={["1"]}
      />
    </div>
  );
};

export default MaterialSearchInputForm;
