export function isFloorOne(floor) {
  const pattern = /^1(-.*)?$/;
  return pattern.test(floor);
}

const CIRCULAR = "Circular";
const RECTANGULAR = "Rectangular";

export function getSmallestFromFloor(combinations, floor) {
  const smallestDesign = combinations.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.iTotal);
      const currentItotal = parseFloat(currentDesign.iTotal);

      if (currentItotal < minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );
  return {
    floor: floor,
    optimalDesign: smallestDesign,
  };
}

export function getLowestCostFromFloor(combinations, product) {
  const filteredCombs = combinations.filter(ele => ele.product == product);
  const smallestDesign = filteredCombs.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.dollarTotal);
      const currentItotal = parseFloat(currentDesign.dollarTotal);

      if (currentItotal < minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );

  return smallestDesign;
}

export function getHighestCostFromFloor(combinations, product) {
  const filteredCombs = combinations.filter(ele => ele.product == product);
  const smallestDesign = filteredCombs.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.dollarTotal);
      const currentItotal = parseFloat(currentDesign.dollarTotal);

      if (currentItotal > minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );

  return smallestDesign;

}

export function getHighestEmissionFromFloor(combinations, product) {
  const filteredCombs = combinations.filter(ele => ele.product == product);
  const smallestDesign = filteredCombs.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.iTotal);
      const currentItotal = parseFloat(currentDesign.iTotal);

      if (currentItotal > minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );

  return smallestDesign;

}

export function getLowestEmissionFromFloor(combinations, product) {
  const filteredCombs = combinations.filter(ele => ele.product == product);
  const smallestDesign = filteredCombs.reduce(
    (designWithMinItotal, currentDesign) => {
      const minITotal = parseFloat(designWithMinItotal.iTotal);
      const currentItotal = parseFloat(currentDesign.iTotal);

      if (currentItotal < minITotal) {
        return currentDesign;
      } else {
        return designWithMinItotal;
      }
    }
  );

  return smallestDesign;

}

export function getDesignWithColumnLengthLessThanOrEqualToFloorOne(
  targetFloor,
  minFloorOneDimensions
) {
  let result = [];
  targetFloor.combinations.forEach((element) => {
      const designGroups = element.designGroups;
      let eligible = true;
      for (let i = 0; i < designGroups.length; i++) {
        if(minFloorOneDimensions.type != designGroups[i].columnShape) {
          eligible = false;
        }
        if (minFloorOneDimensions.type == CIRCULAR) {
          if (designGroups[i].dTrial > minFloorOneDimensions.dTrial[i]) {
            eligible = false;
          }
        } else {
          if (
            designGroups[i].bTrial > minFloorOneDimensions.bTrial[i] ||
            designGroups[i].hTrial > minFloorOneDimensions.hTrial[i]
          ) {
            eligible = false;
          }
        }
      }
      if (eligible) {
        result.push(element);
      }    
  });

  return result;
}

export function getDesignWithColumnLengthLessThanOrEqualToFloorOneForFixedMixOptimization(
  targetCombs,
  minFloorOneDimensions
) {
  let result = [];
    targetCombs.forEach((element) => {
      const designGroups = element.designGroups;
      let eligible = true;
      for (let i = 0; i < designGroups.length; i++) {
        if(minFloorOneDimensions.type != designGroups[i].columnShape) {
          eligible = false;
        }
        if (minFloorOneDimensions.type == CIRCULAR) {
          if (designGroups[i].dTrial > minFloorOneDimensions.dTrial[i]) {
            eligible = false;
          }
        } else {
          if (
            designGroups[i].bTrial > minFloorOneDimensions.bTrial[i] ||
            designGroups[i].hTrial > minFloorOneDimensions.hTrial[i]
          ) {
            eligible = false;
          }
        }
      }
      if (eligible) {
        result.push(element);
      }    
  });

  return result;
}

export function getDesignsWithAreaLessThanOrEqualToFloorOne(
  minAreas,
  targetFloor
) {
  let result = [];
  targetFloor.combinations.forEach((element) => {
    const designGroups = element.designGroups;
    let eligible = true;
    for (let i = 0; i < designGroups.length; i++) {
      if (minAreas[i] < getArea(designGroups[i])) {
        eligible = false;
      }
    }
    if (eligible) {
      result.push(element);
    }
  });
  return result;
}

export function getMinimumAreas(floorGroupOne) {
  const minAreas = [];
  for (let i = 0; i < floorGroupOne.designGroups.length; i++) {
    let designGroup = floorGroupOne.designGroups[i];
    let minArea = 0;
    if (designGroup.dTrial) {
      // circular column
      minArea = (Math.PI * Math.pow(designGroup.dTrial, 2)) / 4;
    } else {
      minArea = designGroup.bTrial * designGroup.hTrial;
    }
    minAreas.push(minArea);
    designGroup.description = generateDescription(
      designGroup.bTrial,
      designGroup.hTrial,
      designGroup.dTrial,
      designGroup.pw
    );
  }

  return minAreas;
}

export function getColumnDimensionsFromFloorOne(floorGroupOne) {
  const minFloorOneDimensions = {
    dTrial: [],
    hTrial: [],
    bTrial: [],
  };

  for (let i = 0; i < floorGroupOne.designGroups.length; i++) {
    let designGroup = floorGroupOne.designGroups[i];
    if (designGroup.dTrial) {
      // circular column
      minFloorOneDimensions.type = CIRCULAR;
      minFloorOneDimensions.dTrial.push(designGroup.dTrial);
    } else {
      minFloorOneDimensions.type = RECTANGULAR;
      minFloorOneDimensions.bTrial.push(designGroup.bTrial);
      minFloorOneDimensions.hTrial.push(designGroup.hTrial);
    }
    designGroup.description = generateDescription(
      designGroup.bTrial,
      designGroup.hTrial,
      designGroup.dTrial,
      designGroup.pw
    );
  }

  return minFloorOneDimensions;
}

function getArea(designGroup) {
  let area = 0;
  if (designGroup.dTrial) {
    area = (Math.PI * Math.pow(designGroup.dTrial, 2)) / 4;
  } else {
    area = designGroup.bTrial * designGroup.hTrial;
  }
  return area;
}

export function assignAtrialAndRecalculate(
  aTrials,
  targetFloor,
  concreteInfo,
  steel
) {
  const steelCost = Math.ceil(steel.cost * 0.765);
  const gwpSteel = Math.ceil(steel.gwp * 0.765);
  const opitmizedCombinations = [];
  targetFloor.forEach((element) => {
    const concrete = concreteInfo.find(
      (concrete) => concrete.id === element.product
    );
    const gwpConcrete = Math.ceil(concrete.gwp * 0.765);
    const concreteCost = Math.ceil(concrete.cost * 0.765);

    const comb = {
      product: element.product,
      iC: 0,
      iSw: 0,
      iTotal: 0,
      dollarC: 0,
      dollarSw: 0,
      dollarTotal: 0,
      designGroups: [],
    };

    for (let i = 0; i < element.designGroups.length; i++) {
      const designGroup = element.designGroups[i];
      const length = designGroup.length / 3.281; // convert to m from ft
      const numberOfColumns = designGroup.numberOfColumns;
      const pw = designGroup.pw * 0.01;
      let dTrial, bTrial, hTrial;
      if (designGroup.dTrial) {
        dTrial = getCircularDimensions(aTrials[i]);
        comb.iC +=
          (((1 - pw) * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          gwpConcrete *
          numberOfColumns;
        comb.iSw +=
          ((pw * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          gwpSteel *
          numberOfColumns;
        comb.dollarC +=
          (((1 - pw) * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          concreteCost *
          numberOfColumns;
        comb.dollarSw +=
          ((pw * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          steelCost *
          numberOfColumns;
      } else {
        [bTrial, hTrial] = getRectangularDimensions(
          aTrials[i],
          designGroup.beta
        );
        comb.iC +=
          (1 - pw) * bTrial * hTrial * length * gwpConcrete * numberOfColumns;
        comb.iSw += pw * bTrial * hTrial * length * gwpSteel * numberOfColumns;
        comb.dollarC +=
          (1 - pw) * bTrial * hTrial * length * concreteCost * numberOfColumns;
        comb.dollarSw +=
          pw * bTrial * hTrial * length * steelCost * numberOfColumns;
      }
      comb.iTotal += comb.iC + comb.iSw;
      comb.dollarTotal += comb.dollarC + comb.dollarSw;

      comb.designGroups.push(
        getDesignGroupProperties({
          name: designGroup.name,
          bTrial: bTrial,
          hTrial: hTrial,
          dTrial: dTrial,
          pw: designGroup.pw,
        })
      );
    }

    opitmizedCombinations.push(comb);
  });
  return opitmizedCombinations;
}


export function assignColumnDimensionsAndRecalculate(
  minDimensions,
  targetFloor,
  concreteInfo,
  steel
) {
  const steelCost = Math.ceil(steel.cost * 0.765);
  const gwpSteel = Math.ceil(steel.gwp * 0.765);
  const opitmizedCombinations = [];
  targetFloor.forEach((element) => {
    const concrete = concreteInfo.find(
      (concrete) => concrete.id === element.product
    );
    const gwpConcrete = Math.ceil(concrete.gwp * 0.765);
    const concreteCost = Math.ceil(concrete.cost * 0.765);

    const comb = {
      product: element.product,
      iC: 0,
      iSw: 0,
      iTotal: 0,
      dollarC: 0,
      dollarSw: 0,
      dollarTotal: 0,
      designGroups: []
    };

    for (let i = 0; i < element.designGroups.length; i++) {
      const designGroup = element.designGroups[i];
      const length = designGroup.length / 3.281; // convert to m from ft
      const numberOfColumns = designGroup.numberOfColumns;
      const pw = designGroup.pw * 0.01;
      let dTrial, bTrial, hTrial;
      if (minDimensions.type == CIRCULAR) {
        dTrial = minDimensions.dTrial[i];
        comb.iC +=
          Math.ceil((((1 - pw) * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          gwpConcrete *
          numberOfColumns);
        comb.iSw +=
          Math.ceil(((pw * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          gwpSteel *
          numberOfColumns);
        comb.dollarC +=
          Math.ceil((((1 - pw) * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          concreteCost *
          numberOfColumns);
        comb.dollarSw +=
          Math.ceil(((pw * Math.PI * Math.pow(dTrial, 2)) / 4) *
          length *
          steelCost *
          numberOfColumns);
      } else {
        bTrial = minDimensions.bTrial[i];
        hTrial = minDimensions.hTrial[i];
        comb.iC +=
          Math.ceil((1 - pw) * bTrial * hTrial * length * gwpConcrete * numberOfColumns);
        comb.iSw += Math.ceil(pw * bTrial * hTrial * length * gwpSteel * numberOfColumns);
        comb.dollarC +=
          Math.ceil((1 - pw) * bTrial * hTrial * length * concreteCost * numberOfColumns);
        comb.dollarSw +=
          Math.ceil(pw * bTrial * hTrial * length * steelCost * numberOfColumns);
      }
      comb.iTotal += comb.iC + comb.iSw;
      comb.dollarTotal += comb.dollarC + comb.dollarSw;

      comb.designGroups.push(
        getDesignGroupProperties({
          name: designGroup.name,
          bTrial: bTrial,
          hTrial: hTrial,
          dTrial: dTrial,
          pw: designGroup.pw,
        })
      );
    }

    opitmizedCombinations.push(comb);
  });
  return opitmizedCombinations;
}

export function addDescriptionToDesignGroup(floorComb) {
  for (let i = 0; i < floorComb.designGroups.length; i++) {
    let designGroup = floorComb.designGroups[i];
    designGroup.description = generateDescription(
      designGroup.bTrial,
      designGroup.hTrial,
      designGroup.dTrial,
      designGroup.pw
    );
  }
}

function getCircularDimensions(area) {
  return Math.sqrt((area * 4) / Math.PI); // TODO: round it maybe ?
}

function getRectangularDimensions(area, beta) {
  const h = Math.sqrt(area / beta);
  return [beta * h, h];
}

function getDesignGroupProperties(designGroup) {
  const obj = {};
  obj.name = designGroup.name;
  obj.description = generateDescription(
    designGroup.bTrial,
    designGroup.hTrial,
    designGroup.dTrial,
    designGroup.pw
  );
  obj.bTrial = designGroup.bTrial;
  obj.hTrial = designGroup.hTrial;
  obj.dTrial = designGroup.dTrial;
  obj.pw = designGroup.pw;

  return obj;
}

export function generateDescription(bTrial, hTrial, dTrial, pw) {
  if (dTrial) {
    return (
      Math.ceil(dTrial * 39.37) +
      "in slender circular column w/  spiral reinf & pw = " +
      pw +
      "%"
    );
  }
  return (
    Math.ceil(bTrial * 39.37) +
    "in x " +
    Math.ceil(hTrial * 39.37) +
    "in" +
    " slender rectangular column w/ tied reinf & pw = " +
    pw +
    "%"
  );
}
