import React from 'react';
import { Avatar, Row, Col, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const PersonComponent = ({ imageUrl, personName, description }) => {
    return (
        <div style={{ margin: '16px', padding: '16px', border: '1px solid #f0f0f0', borderRadius: '8px' }}>
            <Row align="middle">
                {/* First Column */}
                <Col span={6} style={{ textAlign: 'center' }}>
                    <Avatar 
                        size={128} 
                        icon={<UserOutlined />} 
                        src={imageUrl} 
                        style={{ border: '2px solid #f0f0f0' }} 
                    />
                </Col>

                {/* Second Column */}
                <Col span={18}>
                    <Title level={4} style={{ marginBottom: '8px' }}>
                        {personName || "John Doe"}
                    </Title>
                    <Paragraph style={{ margin: 0, whiteSpace: 'pre-line'}}>
                        {description || "A brief description about the person goes here. \n\nIt can span multiple lines if needed."}
                    </Paragraph>
                </Col>
            </Row>
        </div>
    );
}

export default PersonComponent;
