import { useEffect, useState } from "react";
import useFormContext from "../../hooks/useFormContext";
import { Spin, Table, Button, Modal, Typography } from "antd";
import { getHighestCostFromFloor, getHighestEmissionFromFloor, getLowestCostFromFloor, getLowestEmissionFromFloor } from "../../utils/floorCalculations";
import AppendixTableModal from "./AppendixTableModal";


const AppendixTable = () => {
    const {Title} = Typography
    const { concreteInfo, floorValues } = useFormContext();
    const [loading, setLoading] = useState(false);
    const [appendixTable, setAppendixTable] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    useEffect(() => {
        setLoading(true);
        const appendixItems = [];
        concreteInfo.forEach(mix => {

            const appendixItem = {}
            appendixItem.mix = mix.id;
            appendixItem.gwp = mix.gwp;
            appendixItem.cost = mix.cost;
            appendixItem.floorLevel = [];

            floorValues.forEach(floor => {
                const obj = {}
                obj.lowestCost = getLowestCostFromFloor(floor.combinations, mix.id);
                obj.highestCost = getHighestCostFromFloor(floor.combinations, mix.id);
                obj.lowestEmission = getLowestEmissionFromFloor(floor.combinations, mix.id);
                obj.highestEmission = getHighestEmissionFromFloor(floor.combinations, mix.id);
                obj.floor = floor.floor;
                appendixItem.floorLevel.push(obj);
            });

            appendixItems.push(appendixItem);

        });
        setAppendixTable(appendixItems);
        setLoading(false);
        console.log("appendix table is ", appendixItems);
    }, [])

    if (loading) {
        return <Spin />
    }
    const openModal = (floorBreakdown) => {        
        setModalData(floorBreakdown);
        setIsModalOpen(true);
      }

    const columns = [
        {
            title: "Mix ID",
            dataIndex: "mix",
            key: "mix"
        },
        {
            title: "GWP (ke CO2-eq/yd3)",
            dataIndex: "gwp",
            key: "gwp"
        },
        {
            title: "Cost (USD/yd3)",
            dataIndex: "cost",
            key: "cost"
        },
        {
            title: "Floor breakdown",
            key: "floorBreakdown",
            render: (record) => (
                <Button onClick={() => openModal(record.floorLevel)}>Show</Button>
            ),
        }
    ]


    return (
        <div>
            <Title level={3}>Optimumn Design Solutions </Title>
            <Table dataSource={appendixTable} columns={columns} />
            <Modal
                title="Floor Breakdown"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                width="150vh"
                footer={[
                    <Button key="back" onClick={() => setIsModalOpen(false)}>
                        Close
                    </Button>
                ]}
            >
                {/* <p>{modalData && JSON.stringify(modalData)}</p> */}
                <AppendixTableModal floorBreakdown={modalData} />
            </Modal>

        </div>
    );

}

export default AppendixTable;