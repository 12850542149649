const columnHeadings = {
  iC: {
    title: (
      <span>
        &Icirc;<sub>c</sub> (kg.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iC",
    key: "iC",
  },
  iSw: {
    title: (
      <span>
        &Icirc;<sub>sw</sub> (kg.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iSw",
    key: "isW",
  },
  iTotal: {
    title: (
      <span>
        &Icirc;<sub>total</sub> (kg.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iTotal",
    key: "iTotal",
  },
  dollarC: {
    title: (<span>$<sub>c</sub> (USD)</span>),
    dataIndex: "dollarC",
      key: "dollarC",
  },
  dollarSw: {
    title: (<span>$<sub>sw</sub> (USD)</span>),
    dataIndex: "dollarSw",
      key: "dollarSw",
  },
  dollarFw: {
    title: (<span>$<sub>fw</sub> (USD)</span>),
    dataIndex: "dollarFw",
      key: "dollarFw",
  },
  dollarTotal: {
    title: (<span>$<sub>total</sub> (USD)</span>),
    dataIndex: "dollarTotal",
      key: "dollarTotal",
  },
  concreteGwp: {
    title: (<span>GWP (kg.Co2-eq/m<sup>3</sup>)</span>),
    dataIndex: "concreteGwp",
    key: "concreteGwp",
  },
  concreteCost: {
    title: (<span>Unit Cost ($/m<sup>3</sup>)</span>),
    dataIndex: "concreteCost",
    key: "concreteCost",
  },
  iCTons: {
    title: (
      <span>
        &Icirc;<sub>c</sub> (ton.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iC",
    key: "iC",
  },
  iSwTons: {
    title: (
      <span>
        &Icirc;<sub>sw</sub> (ton.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iSw",
    key: "isW",
  },
  iTotalTons: {
    title: (
      <span>
        &Icirc;<sub>total</sub> (ton.Co<sub>2</sub>-eq)
      </span>
    ),
    dataIndex: "iTotal",
    key: "iTotal",
  },
  dollarCThousands: {
    title: (<span>$<sub>c</sub>  (x10<sup>3</sup>USD)</span>),
    dataIndex: "dollarC",
      key: "dollarC",
  },
  dollarSwThousands: {
    title: (<span>$<sub>sw</sub> (x10<sup>3</sup>USD)</span>),
    dataIndex: "dollarSw",
      key: "dollarSw",
  },
  dollarFwThousands: {
    title: (<span>$<sub>fw</sub> (x10<sup>3</sup>USD)</span>),
    dataIndex: "dollarFw",
      key: "dollarFw",
  },
  dollarTotalThousands: {
    title: (<span>$<sub>total</sub> (x10<sup>3</sup>USD)</span>),
    dataIndex: "dollarTotal",
      key: "dollarTotal",
  },
};

export default columnHeadings;
