import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import AuthContext from "./AuthContext";

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkUser(); // Check if a user is already authenticated
  }, []);

  const checkUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);
    } catch (error) {
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      console.log("Setting user to null");
      setUser(null);
    } catch (error) {
      console.log("Could not sign out");
    }
  };

  if (isLoading) {
    // Show a loading spinner or placeholder while checking user authentication status
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
