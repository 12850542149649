import React, { useState, useEffect } from "react";
import {
  InputNumber,
  Form,
  Typography,
  Select,
  Table,
  Tooltip,
  Checkbox,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import useFormContext from "../../../hooks/useFormContext";
const {Title} = Typography;
//TODO: whenever sway type changes, the sid needs to be changed for all design groups. This is because
// sid depends on sway type. By default sway type is "Sway"
const SlendernessInputForm = () => {
  const [form] = Form.useForm();
  const [sid, setSid] = useState({});
  const { designGroups, setDesignGroups, swayType, setSwayType } =
    useFormContext();
  const [m1m2, setm1m2] = useState({});
  const SWAY = "Sway";
  const NONSWAY = "Non Sway";

  const onFinish = (values) => {
    console.log("Values are ", values);
  };

  const handleOnChange = (value) => {
    if (value === "sway") {
      setSid(22);
      setSwayType(SWAY);
    } else {
      setSid(34);
      setSwayType(NONSWAY);
    }
  };

  const useDefaultValue = () => {
    const newDesignGroups = [...designGroups];
    newDesignGroups.forEach((obj) => {
      obj.m1 = 1;
      obj.m2 = 10000;
      if (obj.floorRange[0] === 1) {
        obj.klu = 13.3;
      } else {
        obj.klu = 10.3;
      }
      obj.sid = calculateSid(obj.m1, obj.m2);
    });
    setDesignGroups(newDesignGroups);
    console.log("after chckebox is ticked, designgroups are ", designGroups);
  };

  useEffect(() => {
    form.setFieldsValue({ sid: sid });
  }, [sid]);

  const handleChange = (record, col, value) => {
    const updatedDesignGroups = [...designGroups];
    const recordIndex = updatedDesignGroups.findIndex(
      (item) => item.designGroup === record.designGroup
    );
    record[col] = value;

    if (col === "klu") {
      updatedDesignGroups[recordIndex].klu = value;
    } else if (col === "m1") {
      updatedDesignGroups[recordIndex].m1 = value;
      updatedDesignGroups[recordIndex].m1m2 = record.m1 / record.m2;
      updatedDesignGroups[recordIndex].sid = Math.min(
        40,
        34 + (12 * record.m1) / record.m2
      );
      updateLocalStates(record);
    } else {
      updatedDesignGroups[recordIndex].m1m2 = record.m1 / record.m2;
      updatedDesignGroups[recordIndex].sid = calculateSid(record.m1, record.m2);
      updatedDesignGroups[recordIndex].m2 = value;
      updateLocalStates(record);
    }

    setDesignGroups(updatedDesignGroups);

    console.log("Record is ", record, "Column is ", col, "Value is ", value);
    console.log("Design group is ", designGroups);
  };

  const updateLocalStates = (record) => {
    setm1m2((prevM1M2) => ({
      ...prevM1M2,
      [record.designGroup]: record.m1 / record.m2,
    }));
    setSid((prevSid) => ({
      ...prevSid,
      [record.designGroup]: calculateSid(record.m1, record.m2),
    }));
  };

  const calculateSid = (m1, m2) => {
    if (swayType === SWAY) return 22;

    if (m2 === 0) return 34;

    return Math.min(40, 34 + (12 * m1) / m2);
  };

  const convertToMeter = (value) => {
    return (value * 0.3048).toFixed(2) + "m";
  };

  const convertToKN = (value) => {
    if (!value) return "";
    return (value * 4.44822).toFixed(2) + "KN.m";
  };

  const columns = [
    {
      title: "Design Group",
      dataIndex: "designGroup",
      key: "designGroup",
    },
    {
      title: "K.Lu (ft)",
      dataIndex: "klu",
      key: "klu",
      render: (_, record) => (
        <span>
          <InputNumber
            onChange={(value) => handleChange(record, "klu", value)}
            value={record.klu}
          />
          <Tooltip
            title={convertToMeter(record.klu)}
            color="#f50"
            placement="bottom"
          >
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: "M1 (Kips)",
      dataIndex: "m1",
      key: "m1",
      render: (_, record) => (
        <span>
          <InputNumber
            onChange={(value) => handleChange(record, "m1", value)}
            value={record.m1}
          />
          <Tooltip
            title={convertToKN(record.m1)}
            color="#f50"
            placement="bottom"
          >
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: "M2 (KIPS)",
      dataIndex: "m2",
      key: "m2",
      render: (_, record) => (
        <span>
          <InputNumber
            onChange={(value) => handleChange(record, "m2", value)}
            value={record.m2}
          />
          <Tooltip
            title={convertToKN(record.m2)}
            color="#f50"
            placement="bottom"
          >
            <InfoCircleOutlined style={{ marginLeft: 5, color: "blue" }} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: "M1/M2",
      dataIndex: "m1/m2",
      key: "m1/m2",
      render: (_, record) => (
        <InputNumber
          disabled
          value={m1m2[record.designGroup] || ""}
          onChange={(value) => handleChange(record, "m1/m2", value)}
        />
      ),
    },
    {
      title: "SId",
      dataIndex: "sid",
      key: "sid",
      render: (_, record) => (
        <InputNumber
          disabled
          value={sid[record.designGroup] || ""}
          // onChange={(value) => handleChange(record, "col1", value)}
        />
      ),
    },
  ];

  return (
    <div style={{margin: 10}}>
      <Title level = {3} style={{textAlign: "left"}}>Slenderness Input</Title>
      <Form
        form={form}
        onFinish={onFinish}
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Form.Item label="Frame Type" name="frameType">
          <Select
            defaultValue="sway"
            style={{ width: 200 }}
            onChange={handleOnChange}
            options={[
              { value: "sway", label: "Sway Frames (unbraced)" },
              { value: "nonSway", label: "Non Sway Frames (braced)" },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Table
            style={{ marginTop: "10px" }}
            dataSource={designGroups}
            columns={columns}
            pagination={true}
          />
        </Form.Item>
      
        <div>
          <Checkbox onChange={useDefaultValue}>Use default value</Checkbox>
        </div>
      </Form>
    </div>
  );
};

export default SlendernessInputForm;
