import Errors from "../components/Constants/Errors";
const BASE_URL = 'https://buildingtransparency.org/api/materials';

export async function fetchMaterials(mf, pageNumber) {
  const queryParams = new URLSearchParams({
    page_number: pageNumber,
    mf: mf,
    page_size: 100
  })
  try {
    const response = await fetch(`${BASE_URL}?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer WH2C0ZqvD8tdw6sTsMaSriMDO97oKL`,
      },
    });

    if (!response.ok) {
      switch (response.status) {
          case 400:
              throw new Error(Errors.HTTP_400);
          case 401:
              throw new Error(Errors.HTTP_401);
          case 404:
              throw new Error(Errors.HTTP_404);
          case 500:
              throw new Error(Errors.HTTP_500);
          default:
            throw new Error(Errors.HTTP_500);
      }
  }

    return response.json();

  } catch (error) {
    throw new Error(error.message);
  }
}