import React, { useState } from "react";

import { Button, Tabs, message } from "antd";


import SummaryTable from "./SummaryTable";
import MatrixByFloor from "./MatrixByFloor";
import MatrixByBuilding from "./MatrixByBuilding";
import FullMatrix from "./FullMatrix";
const DisplayMatrixTabs = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [messageApi, contextHolder] = message.useMessage();

  const onChange = (activeKey) => {
    setActiveTab(activeKey);
  };


  const items = [
    {
      key: "1",
      label: `Summary`,
      children: <SummaryTable />,
    },
    {
      key: "2",
      label: `By Floor`,
      children: <MatrixByFloor />,
      // disabled: true,
    },
    {
      key: "3",
      label: `By Building`,
      children: <MatrixByBuilding />
      //   disabled: thirdTabDisabled,
    },
    {
      key: "4",
      label: `Full Matrix`,
      children: <FullMatrix />
    },
  ];

  return (
    <div>
      {contextHolder}
      <Tabs
        style={{ margin: "20px" }}
        activeKey={activeTab}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default DisplayMatrixTabs;
