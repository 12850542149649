import { useState } from "react";
import useFormContext from "../../../hooks/useFormContext";

import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Typography,
  Radio,
} from "antd";
const { Title } = Typography;
const ColumnPartitionInput = ({ groupName, start, end, index }) => {
  // global context variables
  const { floorPlanInfo, setFloorPlanInfo, designGroups, setDesignGroups } =
    useFormContext(); // {group: "Group name", floorRange: [from,to]}

  // local variables ---------------
  const [isSameForAllFloors, setIsSameForAllFloors] = useState(1);
  const [form] = Form.useForm();

  // -------------------

  const getFloors = (start, end) => {
    const floorsToReturn = [];
    for (let i = start; i <= end; i++) {
      const newFloor = { value: i, label: "Floor " + i };
      floorsToReturn.push(newFloor);
    }

    return floorsToReturn;
  };

  const generateGroupID = (columnGroups, floorNumber) => {
    columnGroups = columnGroups.slice(0, 3);
    if (isSameForAllFloors) {
      return `${columnGroups}-${"Col"}-${"Floor"}-${start}-${end}`;
    } else return `${columnGroups}-${"Col"}-${"Floor"}-${floorNumber}`;
  };

  const generateFloorRange = (start, end, floorNumber) => {
    if (isSameForAllFloors) {
      return [start, end];
    } else return [floorNumber, floorNumber];
  };

  const onChange = (e) => {
    setIsSameForAllFloors(e.target.value);
  };

  const addDesignGroup = (values) => {
    const {
      numberOfColumns,
      length,
      columnGroups,
      boundaryConditions,
      unspportedLength,
      floorNumber,
    } = values;

    const row = {
      numberOfColumns,
      length,
      columnGroups,
      boundaryConditions,
      unspportedLength,
      floorNumber,
      groupName
    };
    row.designGroup = generateGroupID(columnGroups, floorNumber);
    row.floorRange = generateFloorRange(start, end, floorNumber);
    console.log("row is ", row);
    setDesignGroups([...designGroups, row]);
  };


  const onFinish = (values) => {
      addDesignGroup(values);   
  };

  return (
    <div>
      <Title level={2}> Group {groupName}</Title>

      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 14 }}
        style={{ maxWidth: 400, marginTop: 20 }}
        initialValues={{ columGroups: "Interior Columns" }}
      >
        <Form.Item
          name="isSameForAllFloors"
          label="Is column length same for all floors"
        >
          <Radio.Group onChange={onChange} value={isSameForAllFloors}>
            <Radio value={0}>No</Radio>
            <Radio value={1}>Yes</Radio>
          </Radio.Group>
        </Form.Item>
        {!isSameForAllFloors && (
          <Form.Item name="floorNumber" label="Select Floor">
            <Select options={getFloors(start, end)} />
          </Form.Item>
        )}

        <Form.Item name="columnGroups" label="Column Group">
          <Select
            defaultValue="Interior Columns"
            options={[
              { value: "Interior Columns", label: "Interior Columns" },
              {
                value: "Exteriror Columns",
                label: "Exteriror Columns",
              },
              {
                value: "Corner Columns",
                label: "Corner Columns",
              },
            ]}
            initialValue="Interior Columns"
          />
        </Form.Item>
        <Form.Item
          name="designGroup"
          label="Design Group"
          dependencies={["columnGroups", "floorNumber"]} // Set dependencies on the previous two items
          initialValue=""
        >
          <Input disabled />
        </Form.Item>
        <Form.Item name="numberOfColumns" label="Number Of Columns">
          <InputNumber />
        </Form.Item>
        <Form.Item name="length" label="Length (ft)">
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="boundaryConditions"
          label="Column-ends boundary conditions"
        >
          <Input />
        </Form.Item>
        <Form.Item name="unspportedLength" label="Effective Unsupported Length">
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Design Group
          </Button>
        </Form.Item>
      </Form>


      
    </div>
  );
};

export default ColumnPartitionInput;
