import { Table, Carousel, Popover } from "antd";
import useFormContext from "../../hooks/useFormContext";
import columnHeadings from "../Constants/ColumnHeadings";
import { useRef, useState } from "react";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import square from "../../assets/sqaure.jpg";
import rectangle from "../../assets/rectangle.jpg";
import circle from "../../assets/circle.jpg";
import square4 from "../../assets/4square.jpg";
import square6 from "../../assets/6square.jpg";
import square8 from "../../assets/8square.jpg";
import square10 from "../../assets/10square.jpg";
import square12 from "../../assets/12square.jpg";
import rectangle4 from "../../assets/4rectangle.jpg";
import rectangle6 from "../../assets/6rectangle.jpg";
import rectangle8 from "../../assets/8rectangle.jpg";
import rectangle10 from "../../assets/10rectangle.jpg";
import rectangle12 from "../../assets/12rectangle.jpg";


const { Title } = Typography;
const SummaryTable = () => {
  const { designGroups } = useFormContext();
  const carouselRef = useRef();
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const handleNext = () => {
    carouselRef.current.next();
    setCurrentGroupIndex(
      (currentGroupIndex + 1 + designGroups.length) % designGroups.length
    );
  };
  const handlePrevious = () => {
    carouselRef.current.prev();
    setCurrentGroupIndex(
      (currentGroupIndex - 1 + designGroups.length) % designGroups.length
    );
  };

  const getImageForDescription = (row) => {
    if (row.dTrial) return circle;
    else if (row.hTrial == row.bTrial) {
      if (row.numberOfRebars == 4) return square4;
      if (row.numberOfRebars == 6) return square6;
      if (row.numberOfRebars == 8) return square8;
      if (row.numberOfRebars == 10) return square10;
      if (row.numberOfRebars == 12) return square12;
      return square;
    }
    else {
      if (row.numberOfRebars == 4) return rectangle4;
      if (row.numberOfRebars == 6) return rectangle6;
      if (row.numberOfRebars == 8) return rectangle8;
      if (row.numberOfRebars == 10) return rectangle10;
      if (row.numberOfRebars == 12) return rectangle12;
    }
    return rectangle;
  };

  const generateTitle = (row) => {
    if (row.dTrial) {
      const d = Math.ceil(row.dTrial * 39.37);
      return (
        <span>
          {d}in<br />
          number of bars- {row.numberOfRebars} <br />
        </span>
      );
    }
    if (row.hTrial == row.bTrial) {
      const a = Math.ceil(row.hTrial * 39.37);
      return (
        <span>
          {a}in x {a}in<br />
          number of bars- {row.numberOfRebars} <br />
        </span>
      );
    } else {
      const b = Math.ceil(row.hTrial * 39.37);
      const l = Math.ceil(row.bTrial * 39.37);
      return (
        <span>
          {b}in x {l}in<br />
          number of bars- {row.numberOfRebars} <br />
        </span>
      );
    }
  };
  const columns = [
    {
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    },
    columnHeadings.concreteCost,
    columnHeadings.concreteGwp,
    {
      title: "Column Description",
      dataIndex: "description",
      key: "description",
      render: (text, row) => (
        <Popover
          content={
            <img src={getImageForDescription(row)} style={{ width: 200 }} />
          }
          title={generateTitle(row)}
        >
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            {text}
          </span>
        </Popover>
      ),
    },
    columnHeadings.iC,
    columnHeadings.iSw,
    columnHeadings.iTotal,
    columnHeadings.dollarC,
    columnHeadings.dollarSw,
    columnHeadings.dollarFw,
    columnHeadings.dollarTotal,
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LeftCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handlePrevious}
        />
        <Title
          level={4}
          style={{ marginLeft: 10, marginRight: 10, paddingBottom: 15 }}
        >
          {" "}
          Design Group {designGroups[currentGroupIndex].designGroup}
        </Title>
        <RightCircleOutlined
          style={{ fontSize: "30px" }}
          onClick={handleNext}
        />
      </div>
      <Carousel ref={carouselRef}>
        {designGroups.map((designGroup, index) => (
          <div>
            <Table
              dataSource={designGroup.combinations}
              columns={columns}
              pagination={true}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SummaryTable;
