import { useState, useRef, useEffect } from "react";
import useFormContext from "../../hooks/useFormContext";
import { Button, Table, Carousel } from "antd";
import { Typography } from "antd";
import FixedColumns from "./FixedColumn";

const { Title } = Typography;

const Optimizer = () => {
  const {
    concreteInfo,
    shapes,
    swayType,
    designGroups,
    setDesignGroups,
    momentMagnifier,
    steel,
    setFloorValues,
    floorValues,
    buildingValues,
    setBuildingValues,
    formworkCost,
    rebar,
  } = useFormContext();
  const pw = [1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0]; // TODO: change this to values you calculate
  const beta = [1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0];
  const shears = ["Spirals", "Ties"]; // TODO: take from prev screen later on
  const [loading, setLoading] = useState(false);
  const [showOptimizations, setShowOptimizations] = useState(false);
  const SQUARE = "Square";
  const RECTANGLULAR = "Rectangular";
  const CIRCULAR = "Circular";

  useEffect(() => {
    generateCombs();
  }, []);

  function generateCombs() {
    let id = 1;
    setLoading(true);
    let designGroupsToBeCalculated = [...designGroups];
    designGroupsToBeCalculated.forEach((designGroup) => {
      const newCombs = [];
      for (const mix of concreteInfo) {
        for (const colShape of shapes) {
          for (const ratio of getRhoValues(colShape)) {
            if (colShape === SQUARE) {
              const obj = {
                id: id++,
                product: mix.id,
                pw: ratio.rho,
                shape: colShape,
                beta: 1.0,
                shearReinforcement: "Ties",
                numberOfRebars: ratio.numberOfRebars,
              };
              newCombs.push(obj);
            } else if (colShape === CIRCULAR) {
              for (const shear of shears) {
                const obj = {
                  id: id++,
                  product: mix.id,
                  pw: ratio.rho,
                  shape: colShape,
                  beta: 1.0,
                  shearReinforcement: shear,
                  numberOfRebars: ratio.numberOfRebars,
                };
                newCombs.push(obj);
              }
            } else {
              for (const b of beta) {
                const obj = {
                  id: id++,
                  product: mix.id,
                  pw: ratio.rho,
                  shape: colShape,
                  beta: b,
                  shearReinforcement: "Ties",
                  numberOfRebars: ratio.numberOfRebars,
                };
                newCombs.push(obj);
              }
            }
          }
        }
      }
      const updatedCombs = calculateBasicParameters(newCombs);
      const finalCombinations = calculateGroupLevelParameters(
        updatedCombs,
        designGroup
      );
      designGroup.combinations = finalCombinations;
    });
    setDesignGroups(designGroupsToBeCalculated);
    const floorLevelDetails = calculateFloorValues(designGroupsToBeCalculated);
    calculateBuildingValues(floorLevelDetails);
    console.log("Final calc design group", designGroups);
    console.log("Final calc floor values", floorLevelDetails);
    console.log("Entrie Buidling values ", buildingValues);
    setLoading(false);
  }

  const initBuildingValues = (numberOfCombinations) => {
    let buildingValues = [];

    for (let i = 0; i < numberOfCombinations; i++) {
      const comb = {
        iC: 0,
        iSw: 0,
        iTotal: 0,
        dollarC: 0,
        dollarSw: 0,
        dollarFw: 0,
        dollarTotal: 0,
      };

      buildingValues.push(comb);
    }

    return buildingValues;
  };

  const getRhoValues = (shape) => {
    let rhoValues = [];
    if (shape == CIRCULAR) {
      let factor = 4;
      const areaOfSteel = rebar.area;
      while(factor * areaOfSteel < 1) {
        factor += 1
      }
      while (factor * areaOfSteel <= 4) {
        rhoValues.push({ rho: factor * areaOfSteel, numberOfRebars: factor });
        factor += 1;
      }
    } else {
      let factor = 4;
      const areaOfSteel = rebar.area;
      while(factor * areaOfSteel < 1) {
        factor += 2
      }
      while (factor * areaOfSteel <= 4) {
        rhoValues.push({ rho: factor * areaOfSteel, numberOfRebars: factor });
        factor += 2;
      }
    }

    return rhoValues;
  };

  const calculateBuildingValues = (floorLevelDetails) => {
    console.log("Floor values are ", floorLevelDetails);
    const buildingValues = initBuildingValues(
      floorLevelDetails[0].combinations.length
    );

    floorLevelDetails.forEach((floor) => {
      for (let i = 0; i < floor.combinations.length; i++) {
        buildingValues[i].iC +=
          (floor.combinations[i].iC * floor.numberOfFloors) / 1000;
        buildingValues[i].iSw +=
          (floor.combinations[i].iSw * floor.numberOfFloors) / 1000;
        buildingValues[i].iTotal +=
          (floor.combinations[i].iTotal * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarC +=
          (floor.combinations[i].dollarC * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarSw +=
          (floor.combinations[i].dollarSw * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarFw +=
          (floor.combinations[i].dollarFw * floor.numberOfFloors) / 1000;
        buildingValues[i].dollarTotal +=
          (floor.combinations[i].dollarTotal * floor.numberOfFloors) / 1000;
        buildingValues[i].product = floor.combinations[i].product;
        buildingValues[i].id = i;
      }
    });

    console.log("Building values before state set: ", buildingValues);
    const roundedValues = round(buildingValues);
    setBuildingValues(roundedValues);
  };

  const round = (values) => {
    values.forEach((comb) => {
      comb.iC = Math.ceil(comb.iC);
      comb.iSw = Math.ceil(comb.iSw);
      comb.iTotal = Math.ceil(comb.iTotal);
      comb.dollarC = Math.ceil(comb.dollarC);
      comb.dollarSw = Math.ceil(comb.dollarSw);
      comb.dollarFw = Math.ceil(comb.dollarFw);
      comb.dollarTotal = Math.ceil(comb.dollarTotal);
    });
    return values;
  };

  const initializeFloorValues = (designGroups) => {
    let floorAggregates = [];
    designGroups.forEach((designGroup) => {
      const floor = getFloor(designGroup.floorRange);
      const floorAggregate = floorAggregates.find((ele) => ele.floor === floor);
      if (!floorAggregate) {
        const floorObj = {
          floor: floor,
          combinations: [],
          numberOfFloors: getNumberOfFloors(designGroup.floorRange),
        };
        for (let i = 0; i < designGroup.combinations.length; i++) {
          const comb = {
            iC: 0,
            iSw: 0,
            iTotal: 0,
            dollarC: 0,
            dollarSw: 0,
            dollarFw: 0,
            dollarTotal: 0,
            designGroups: [],
          };
          floorObj.combinations.push(comb);
        }
        floorAggregates.push(floorObj);
      }
    });
    return floorAggregates;
  };

  const getNumberOfFloors = (range) => {
    return range[1] - range[0] + 1;
  };

  const getFloor = (range) => {
    let floor = "";
    if (range[1] - range[0] === 0) {
      floor = range[0].toString();
    } else {
      floor = range[0].toString() + "-" + range[1].toString();
    }

    return floor;
  };

  const calculateFloorValues = (designGroups) => {
    const floorAggs = initializeFloorValues(designGroups);
    console.log("Init: ", floorAggs);

    designGroups.forEach((designGroup) => {
      const floorAgg = floorAggs.find(
        (ele) => ele.floor === getFloor(designGroup.floorRange)
      );
      const combinations = floorAgg.combinations;

      for (let i = 0; i < designGroup.combinations.length; i++) {
        combinations[i].product = designGroup.combinations[i].product;
        combinations[i][designGroup.designGroup] =
          designGroup.combinations[i].description;
        combinations[i].iC +=
          designGroup.combinations[i].iC * designGroup.numberOfColumns;
        combinations[i].iSw +=
          designGroup.combinations[i].iSw * designGroup.numberOfColumns;
        combinations[i].iTotal +=
          designGroup.combinations[i].iTotal * designGroup.numberOfColumns;
        combinations[i].dollarC +=
          designGroup.combinations[i].dollarC * designGroup.numberOfColumns;
        combinations[i].dollarSw +=
          designGroup.combinations[i].dollarSw * designGroup.numberOfColumns;
        combinations[i].dollarFw +=
          designGroup.combinations[i].dollarFw * designGroup.numberOfColumns;
        combinations[i].dollarTotal +=
          designGroup.combinations[i].dollarTotal * designGroup.numberOfColumns;
        combinations[i].id = i;

        const designGroupObj = {
          name: designGroup.designGroup,
          bTrial: designGroup.combinations[i].bTrial,
          hTrial: designGroup.combinations[i].hTrial,
          dTrial: designGroup.combinations[i].dTrial,
          pw: designGroup.combinations[i].pw,
          length: designGroup.length,
          numberOfColumns: designGroup.numberOfColumns,
          beta: designGroup.combinations[i].beta,
          columnShape: designGroup.combinations[i].columnShape,
        };
        combinations[i].designGroups.push(designGroupObj);
      }
    });
    console.log("After floor agg - ", floorAggs);
    const roundedFloorAggs = roundToTwoDecimal(floorAggs);
    setFloorValues(roundedFloorAggs);
    return floorAggs;
  };

  const roundToTwoDecimal = (floors) => {
    floors.forEach((floor) => {
      floor.combinations.forEach((comb) => {
        comb.iC = Math.ceil(comb.iC);
        comb.iSw = Math.ceil(comb.iSw);
        comb.iTotal = Math.ceil(comb.iTotal);
        comb.dollarC = Math.ceil(comb.dollarC);
        comb.dollarSw = Math.ceil(comb.dollarSw);
        comb.dollarFw = Math.ceil(comb.dollarFw);
        comb.dollarTotal = Math.ceil(comb.dollarTotal);
      });
    });
    return floors;
  };

  const calculateBasicParameters = (newCombs) => {
    const updatedCombs = newCombs.map((comb) => {
      const concrete = concreteInfo.find(
        (element) => element.id === comb.product
      );
      return {
        ...comb,
        compressiveStrength: getCompressiveStrength(concrete),
        ec: Math.ceil(getEc(concrete)),
        concreteGwp: (0.765 * concrete.gwp).toFixed(1),
        concreteCost: (0.765 * concrete.cost).toFixed(1),
      };
    });

    return updatedCombs;
  };

  const calculateGroupLevelParameters = (updatedCombs, designGroup) => {
    const draftArea = designGroup.trialArea / 10.764;
    const pu = designGroup.pu / 224.809;
    const du = designGroup.du / 224.809;
    const beta = pu / du;

    updatedCombs.forEach((comb) => {
      comb.draftArea = draftArea;
      comb.pu = pu;
      comb.du = du;
      comb.beta2 = beta;
      const ratio = designGroup.klu / (designGroup.sid * 3.281);
      comb.aGshort = calculateAgShort(comb);

      if (comb.shape === CIRCULAR) {
        comb.aGlimit = (4 * Math.PI * Math.pow(ratio, 2)).toFixed(3);
        comb.aGmin = (
          Math.PI *
          Math.pow(designGroup.minColDiameter / 2, 2) *
          (1 / 1550)
        ).toFixed(3);
        comb.aGlong = calculateAgLong(
          CIRCULAR,
          comb.compressiveStrength,
          designGroup,
          comb
        );
      } else {
        comb.aGlimit = (12 * comb.beta * Math.pow(ratio, 2)).toFixed(3);
        comb.aGmin = (
          comb.beta *
          Math.pow(designGroup.minColWidth, 2) *
          (1 / 1550)
        ).toFixed(3);
        comb.aGlong = calculateAgLong(
          RECTANGLULAR,
          comb.compressiveStrength,
          designGroup,
          comb
        );
      }

      const returnFromTrialCalculation = calculateAgTrial(
        comb.aGmin,
        comb.aGshort,
        comb.aGlong,
        comb.aGlimit
      );
      comb.agTrial = returnFromTrialCalculation[0];
      comb.columnType = returnFromTrialCalculation[1];

      const bDandHtrial = calculateBdAndHTrial(comb);
      comb.bTrial = Math.ceil(bDandHtrial.bTrial * 20) / 20;
      comb.dTrial = Math.ceil(bDandHtrial.dTrial * 20) / 20;
      comb.hTrial = Math.ceil(bDandHtrial.hTrial * 20) / 20;
      comb.columnShape = bDandHtrial.columnShape;

      const iCIswAndItotal = calculateIcIswAndItotal(comb, designGroup);
      comb.iC = iCIswAndItotal[0].toFixed(2);
      comb.iSw = iCIswAndItotal[1].toFixed(2);
      comb.iTotal = iCIswAndItotal[2].toFixed(2);

      const dollarValues = calculateDollarValues(comb, designGroup);
      comb.dollarC = dollarValues[0].toFixed(2);
      comb.dollarSw = dollarValues[1].toFixed(2);
      comb.dollarFw = dollarValues[2].toFixed(2);
      comb.dollarTotal = dollarValues[3].toFixed(2);

      comb.description = generateDescription(comb, comb.columnType);
    });

    return updatedCombs;
  };

  const generateDescription = (comb, columnType) => {
    if (comb.shape === CIRCULAR) {
      return (
        Math.ceil(comb.dTrial * 39.37) +
        "in " +
        columnType +
        " circular column w/  spiral reinf & pw = " +
        comb.pw +
        "%"
      );
    }
    return (
      Math.ceil(comb.bTrial * 39.37) +
      "in x " +
      Math.ceil(comb.hTrial * 39.37) +
      "in " +
      columnType +
      " rectangular column w/ tied reinf & pw = " +
      comb.pw +
      "%"
    );
  };

  const calculateIcIswAndItotal = (comb, designGroup) => {
    // const concrete = concreteInfo.find((obj) => obj.id === comb.product);
    // const gwp = concrete.gwp * 0.765; // convert to m3 from yd3
    // const unitCost = concrete.cost * 0.765; // convert to cost/m3 from cost/yd3
    const area =
      comb.shape === CIRCULAR
        ? (Math.PI * Math.pow(comb.dTrial, 2)) / 4
        : comb.bTrial * comb.hTrial;
    const iC =
      ((1 - 0.01 * comb.pw) * area * designGroup.length * comb.concreteGwp) /
      3.281;
    const iSw =
      (0.01 * comb.pw * area * designGroup.length * steel.gwp * 0.765) / 3.281;
    const iTotal = iC + iSw; // TODO: should i ignore if one of the values is NaN ?

    return [iC, iSw, iTotal];
  };

  const calculateDollarValues = (comb, designGroup) => {
    const area =
      comb.shape === CIRCULAR
        ? (Math.PI * Math.pow(comb.dTrial, 2)) / 4
        : comb.bTrial * comb.hTrial;

    const perimeter =
      comb.shape === CIRCULAR
        ? Math.PI * comb.dTrial
        : 2 * (comb.bTrial + comb.hTrial);

    const dollarC =
      ((1 - 0.01 * comb.pw) * area * designGroup.length * comb.concreteCost) /
      3.281;
    const dollarSw =
      (0.01 * comb.pw * area * designGroup.length * steel.cost * 0.765) / 3.281;

    const dollarFw =
      (perimeter * designGroup.length * (formworkCost * 10.76)) / 3.281; // multiply formwork to convert to usd/m2
    const dollarTotal = dollarC + dollarSw + dollarFw;

    return [dollarC, dollarSw, dollarFw, dollarTotal];
  };

  const calculateBdAndHTrial = (comb) => {
    const returnObject = {};

    if (comb.shape === CIRCULAR) {
      returnObject.dTrial = Math.pow((4 / Math.PI) * comb.agTrial, 0.5);
      returnObject.columnShape = CIRCULAR;
    } else {
      returnObject.bTrial = Math.pow(comb.beta * comb.agTrial, 0.5);
      returnObject.hTrial = returnObject.bTrial / comb.beta;
      returnObject.columnShape = RECTANGLULAR;
    }

    return returnObject;
  };

  const calculateAgTrial = (min, short, long, limit) => {
    let ans, columnType;
    if (!(short > min)) short = min;

    if (short > limit) {
      ans = short;
      columnType = "Short";
    } else {
      if (!(long > min)) long = min;
      ans = long;
      columnType = "Slender";
    }
    return [ans, columnType];
  };

  const getCompressiveStrength = (concrete) => {
    return parseInt(concrete.compressiveStrength) / 145.038;
  };

  const calculateAgLong = (shape, fc, designGroup, comb) => {
    const factor = fc >= 120 ? 0.7 : 0.25;
    let numerator, denominator, outsideFactor;
    if (swayType === "Non Sway") {
      if (shape === CIRCULAR) {
        numerator = 4 * momentMagnifier * comb.pu;
        denominator =
          factor *
          comb.ec *
          Math.PI *
          (0.75 * momentMagnifier -
            0.45 -
            (0.3 * designGroup.m1) / designGroup.m2);
        outsideFactor = designGroup.klu * 3.281; //klu
      } else {
        numerator = 16 * comb.beta * momentMagnifier * comb.pu;
        denominator =
          factor *
          comb.ec *
          (momentMagnifier - 0.6 - (0.4 * designGroup.m1) / designGroup.m2);
        outsideFactor = designGroup.klu / (3.281 * Math.PI); // klu
      }
    } else {
      if (shape === CIRCULAR) {
        numerator = 4 * comb.pu;
        denominator =
          factor * comb.ec * Math.PI * (0.75 - 0.75 / momentMagnifier);
        outsideFactor = designGroup.klu / 3.281; // klu
      } else {
        numerator = 16 * comb.beta * comb.pu; // 3.885 = comb.pu
        denominator = factor * comb.ec * (1 - 1 / momentMagnifier);
        outsideFactor = designGroup.klu / (3.281 * Math.PI); // klu
      }
    }

    return (outsideFactor * Math.pow(numerator / denominator, 0.5)).toFixed(3);
  };

  const calculateAgShort = (comb) => {
    const pu = comb.pu;
    const oneMinusPw = 1 - 0.01 * comb.pw;
    const fc = comb.compressiveStrength;
    const outsideFactor = comb.shearReinforcement === "Ties" ? 0.52 : 0.6375;
    const yieldStrength = steel.yieldStrength;

    return (
      pu /
      (outsideFactor *
        oneMinusPw *
        (0.85 * fc + (yieldStrength * 0.01 * comb.pw) / (145.038 * oneMinusPw)))
    ).toFixed(3);
  };

  const getEc = (concrete) => {
    const compressiveStrength = concrete.compressiveStrength / 145.038;
    const sqRootOfCompressiveStrength = Math.sqrt(compressiveStrength);

    const eCInitial = sqRootOfCompressiveStrength / Math.sqrt(145.038);
    if (compressiveStrength < 120) {
      return 57000 * eCInitial;
    }
    return 49000 * eCInitial;
  };

  if (loading) {
    return <div>Loading ...</div>;
  }

  const columns = [
    {
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "pw %",
      dataIndex: "pw",
      key: "pw",
    },
    {
      title: "Column Shapes",
      dataIndex: "shape",
      key: "shape",
    },
    {
      title: "Beta",
      dataIndex: "beta",
      key: "beta",
    },
    {
      title: "Shear Reinforcement",
      dataIndex: "shearReinforcement",
      key: "shearReinforcement",
    },
    {
      title: (
        <span>
          Aglong (m<sup>2</sup>)
        </span>
      ),
      dataIndex: "aGlong",
      key: "aGlong",
    },
    {
      title: (
        <span>
          Aglimit (m<sup>2</sup>)
        </span>
      ),
      dataIndex: "aGlimit",
      key: "aGlimit",
    },
    {
      title: (
        <span>
          Agmin (m<sup>2</sup>)
        </span>
      ),
      dataIndex: "aGmin",
      key: "aGmin",
    },
    {
      title: (
        <span>
          Agshort (m<sup>2</sup>)
        </span>
      ),
      dataIndex: "aGshort",
      key: "aGshort",
    },
    {
      title: (
        <span>
          AgTrial (m<sup>2</sup>)
        </span>
      ),
      dataIndex: "agTrial",
      key: "agTrial",
    },
    {
      title: <span>bTrial (m)</span>,
      dataIndex: "bTrial",
      key: "bTrial",
    },
    {
      title: <span>hTrial (m)</span>,
      dataIndex: "hTrial",
      key: "hTrial",
    },
    {
      title: <span>dTrial (m)</span>,
      dataIndex: "dTrial",
      key: "dTrial",
    },
    {
      title: (
        <span>
          &Icirc;<sub>c</sub> (kg.Co<sub>2</sub>-eq)
        </span>
      ),
      dataIndex: "iC",
      key: "iC",
    },
    {
      title: (
        <span>
          &Icirc;<sub>sw</sub> (kg.Co<sub>2</sub>-eq)
        </span>
      ),
      dataIndex: "iSw",
      key: "isW",
    },
    {
      title: (
        <span>
          &Icirc;<sub>total</sub> (kg.Co<sub>2</sub>-eq)
        </span>
      ),
      dataIndex: "iTotal",
      key: "iTotal",
    },
  ];

  const dsiplayOptimizations = () => {
    setShowOptimizations(true);
  };

  return (
    <div style={{ margin: 10 }}>
      {!showOptimizations && (
        <Button onClick={dsiplayOptimizations}> Show optimizations</Button>
      )}
      {showOptimizations && (
        <FixedColumns
          floorLevelValues={floorValues}
          buildingLevelValues={buildingValues}
        />
      )}
    </div>
  );
};

export default Optimizer;
