import { Table } from "antd";
import React, { useState, useEffect } from "react";
import { generateDescription } from "../../utils/floorCalculations";

const AppendixTableModal = ({ floorBreakdown }) => {
  // Helper function to get the design group type
  const getDesignGroupType = (name) => {
    if (name.startsWith("Int")) return "Interior";
    if (name.startsWith("Ext")) return "Exterior";
    if (name.startsWith("Cor")) return "Corner";
    return "unknown";
  };

  // Function to transform the floor data to the format needed for the table
  const getTransformedData = (floorBreakdown) => {
    const tableData = floorBreakdown?.map(floor => {
      return ['lowestCost', 'highestCost', 'lowestEmission', 'highestEmission'].map((solutionKey, index) => {
        // Build the base row for this solution
        const row = {
          key: `${floor.floor}-${solutionKey}`,
          floor: floor.floor,
          selectedSolution: solutionKey,
          rowSpan: index === 0 ? 4 : 0,
        };

        // Iterate over designGroups and assign bTrial values to their respective columns
        floor[solutionKey]?.designGroups?.forEach(group => {
          const type = getDesignGroupType(group.name);
          if (type !== "unknown") {
            row[type] = generateDescription(group.bTrial, group.hTrial, group.dTrial, group.pw);
          }
        });

        return row;
      });
    })?.flat(); // Flatten the array of arrays into a single array
    return tableData || [];
  };

  // Generate column definitions dynamically based on design group types in the data
  const generateColumns = (floorBreakdown) => {
    const designGroupTypes = new Set();
    floorBreakdown?.forEach(floor => {
      ['lowestCost', 'highestCost', 'lowestEmission', 'highestEmission'].forEach(key => {
        floor[key]?.designGroups?.forEach(group => {
          const type = getDesignGroupType(group.name);
          if (type !== "unknown") {
            designGroupTypes.add(type);
          }
        });
      });
    });

    // Static columns
    const staticColumns = [
      {
        title: 'Floor',
        dataIndex: 'floor',
        key: 'floor',
        render: (value, record) => ({
          children: value,
          props: {
            rowSpan: record.rowSpan,
          },
        }),
      },
      {
        title: 'Selected Solution',
        dataIndex: 'selectedSolution',
        key: 'selectedSolution',
        render: text => text.replace(/([A-Z])/g, ' $1').toLowerCase(),
      },
    ];

    // Dynamic columns for each design group type
    const dynamicColumns = Array.from(designGroupTypes).map(type => ({
      title: `${type.charAt(0).toUpperCase() + type.slice(1)} Column`,
      dataIndex: type,
      key: type,
      render: text => text || 'N/A',
    }));

    return [...staticColumns, ...dynamicColumns];
  };

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (floorBreakdown) {
      setData(getTransformedData(floorBreakdown));
      setColumns(generateColumns(floorBreakdown));
    }
  }, [floorBreakdown]);

  return (
    <div>
      <Table dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default AppendixTableModal;
