import { Button, message, Steps, theme, Space, Layout } from "antd";
import React, { useState } from "react";

import design from "../../assets/design.png";
import floorPlan from "../../assets/floorPlan.png";
import matrix from "../../assets/matrix.png";
import steelIcon from "../../assets/steel.png";
import truck from "../../assets/truck.svg";
import summary from "../../assets/summary.png";
import graph from "../../assets/graph.svg";
import SteelReinforcementInput from "./SteelReinforcementInput/SteelReinforcementInput";

import DisplayMatrixTabs from "../DisplayComponents/DisplayMatrixTabs";
import Plots from "../DisplayComponents/Plots";
import DesignInputTabs from "./DesignInputs/DesignInputTabs";
import FloorPlanTabs from "./FloorPlanInput/FloorPlanTabs";
import useFormContext from "../../hooks/useFormContext";
import OptimizationTabs from "../DisplayComponents/OptimizationTabs";
import { checkInput, checkSteelReinforcementSection } from "../../utils/errorValidations";
import MaterialInput from "./ConcreteInput/MaterialInput";
import Optimizer from "../DisplayComponents/Optimizer";
const { Sider, Content, Footer } = Layout;
const ConcreteIcon = () => (
  <img
    src={truck}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const Graph = () => (
  <img
    src={graph}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const SummaryIcon = () => (
  <img
    src={summary}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const FloorPlanIcon = () => (
  <img
    src={floorPlan}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);
const SteelIcon = () => (
  <img
    src={steelIcon}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const DesignIcon = () => (
  <img
    src={design}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const MatrixIcon = () => (
  <img
    src={matrix}
    alt="Custom Icon"
    style={{ width: "30px", height: "30px" }}
  />
);

const steps = [
  {
    title: "Concrete Input",
    // icon: <ConcreteIcon />,
    
    content: <MaterialInput />,
    
  },
  {
    title: "Steel Input",
    // icon: <SteelIcon />,
    content: <SteelReinforcementInput />,
  },
  {
    title: "Floor Plan Input",
    // icon: <FloorPlanIcon />,
    content: <FloorPlanTabs />,
  },
  {
    title: "Design Input",
    // icon: <DesignIcon />,
    content: <DesignInputTabs />,
  },
  {
    title: "Optimized Results",
    // icon: <MatrixIcon />,
    content: <Optimizer />,
  },
  {
    title: "Plots",
    // icon: <Graph />,
    content: <Plots />,
  },
  {
    title: "Summary",
    // icon: <SummaryIcon />,
    content: <DisplayMatrixTabs />,
  }
];

const UserInputForm = () => {
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const { steel, concreteInfo  } = useFormContext();
  const [messageApi, contextHolder] = message.useMessage();
  
const decodeError = (errorObject) => {
  console.log(errorObject);
  if(!errorObject.isValid) {
    throwError(errorObject.errorMessage);
    return false;
  }
  return true;
}

  const checkErrorValidations = (move) => {
    switch(current) {
      case 0: 
        console.log("calling error validation")
        move = decodeError(checkInput(concreteInfo));
        break;
      case 1:
        move = decodeError(checkSteelReinforcementSection(steel));
        break;
    }
    return move;
  }

  const next = () => {
    let move = true;
    move = checkErrorValidations(move);
    move && setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));

  const siderStyle = {
    display: "flex", // Make the container a flex container
    flexDirection: "column", // Stack child elements vertically
    justifyContent: "center", // Center content vertically
    textAlign: "center",
    textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: "white",
    marginTop: 5,
    paddingTop: 15,
  };

  const newCotentStyle = {
    textAlign: "center",
    // color: token.colorTextTertiary,

    // borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 5,
    height: "80vh", // Set a fixed height for the content area
    overflowY: "auto", // Enable vertical scrolling when content exceeds the height
  };

  const Buttons = () => {
    return (
      <div style={newButtonsContainerStyle}>
        {current > 0 && (
          <Button
            style={{
              margin: "0 6px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
      </div>
    );
  };

  const newButtonsContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };


  const throwError = (errorMessage) => {
    messageApi.open({
      type: "error",
      content: errorMessage,
    });
  };

  return (
    <div>
      {contextHolder}
      <Layout style = {{height: "90vh"}}>
        <Sider style={siderStyle}>
          <Steps
            style={{ padding: 10 }}
            current={current}
            items={items}
            direction="vertical"
          />
        </Sider>
        <Layout>
          <Content style={newCotentStyle}>
            <div>{steps[current].content}</div>
          </Content>
          <Footer>
            <Buttons />
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};
export default UserInputForm;
