import { Form, InputNumber } from "antd";
import { React, useEffect } from "react";
import useFormContext from "../../../hooks/useFormContext";
const FormworkInput = () => {
    const [form] = Form.useForm();
    const { setFormworkCost, formworkCost } = useFormContext();

    const handleCostChange = (value) => {
        setFormworkCost(value);
    }

    useEffect(() => {
      form.setFieldsValue({ formworkCost });
    }, [formworkCost, form]);

  return (
    <div>
      <Form form={form} style={{ margin: 10 }}>
        <Form.Item
          name="formworkCost"
          label="Enter the formwork cost"
        >
          <InputNumber
              addonAfter = "USD/ft2"
              onChange={(value) => handleCostChange(value)}
            />
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormworkInput;
