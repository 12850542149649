import { Button, Form, Input, Select, Typography, Spin } from "antd";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Errors from "./Constants/Errors";
const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const goToLogIn = () => {
    navigate("/login");
  };
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    const { email, password } = values;
    console.log("Received values of form: ", password);
    setLoading(true);
    try {
      await Auth.signUp(email, password);
      console.log("success ");
      setError(false);
      navigate("/confirmemail", { state: { username } });
    } catch (err) {
      if (err.code === "UsernameExistsException") {
        setError(true);
      }
      console.log("Something wrong");
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "24px",
      }}
    >
      <Title
        style={{
          marginBottom: "30px",
        }}
        level={2}
        underline
      >
        Sign up
      </Title>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        style={{
          maxWidth: 700,
        }}
        scrollToFirstError
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input onChange={(e) => setUsername(e.target.value)} />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
            {
              pattern:
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,}$/,
              message:
                "Password must include at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: Errors.CONFIRM_PASSWORD,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(Errors.PASSWORD_DOES_NOT_MATCH)
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <div>
            <Button type="primary" htmlType="submit">
              Register
            </Button>
            {loading && <Spin />}
            <div style={{ marginTop: 10 }}>
              Have an account ?{" "}
              <Button type="link" onClick={goToLogIn}>
                Log in
              </Button>{" "}
            </div>
            <div style={{ marginTop: 10 }}>
              {error && <Text type="danger">{Errors.EMAIL_EXISTS}</Text>}
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export default Register;
