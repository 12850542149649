import Errors from "../components/Constants/Errors";

// same function for all sections for now, change it later to make errors more tailored for each section
export function checkInput(input, message) {
    console.log(input)
  if (!input || input.length == 0) {
    return {isValid: false, errorMessage: message};
  }

  return {isValid: true, errorMessage: "" };
}

export function checkSlendernessInput(designGroups, message) {
  const isValid = designGroups.every(obj =>
    obj.hasOwnProperty('klu') && obj.klu != null && obj.klu !== 0 &&
    obj.hasOwnProperty('m1') && obj.m1 != null && obj.m1 !== 0 &&
    obj.hasOwnProperty('m2') && obj.m2 != null && obj.m2 !== 0
  );
  
  if(!isValid) {
    return {isValid: false, errorMessage: message};
  }

  return {isValid: true, errorMessage: ""};
  
}


export function checkLoadInput(designGroups, message) {
  const isValid = designGroups.every(obj =>
    obj.hasOwnProperty('cm') && obj.cm != null && obj.cm !== 0 &&
    obj.hasOwnProperty('du') && obj.du != null && obj.du !== 0 &&
    obj.hasOwnProperty('pu') && obj.pu != null && obj.pu !== 0
  );
  
  if(!isValid) {
    return {isValid: false, errorMessage: message};
  }

  return {isValid: true, errorMessage: ""};
  
}

export function checkSteelReinforcementSection(input) {
  if (!input) {
    return { isValid: false, errorMessage: Errors.STEEL_REBAR_MISSING };
  }
  return { isValid: true, errorMessage: "" };
}
