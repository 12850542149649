import columnHeadings from "../Constants/ColumnHeadings";
import { Table } from "antd";
import React from "react";
const TempTable = ({dataSource}) => {

    const getDesignGroupType = (name) => {
        if (name.startsWith("Int")) return "Interior";
        if (name.startsWith("Ext")) return "Exterior";
        if (name.startsWith("Cor")) return "Corner";
        return "Unknown";
      };

    //   const children = [
    //     {
    //         title: "bTrial (ft)",
    //         dataIndex: "bTrial",
    //         key: "bTrial",
    //     },
    //     {
    //         title: "hTrial (ft)",
    //         dataIndex: "hTrial",
    //         key: "dTrial",
    //     },
    //     {
    //         title: "dTrial (ft)",
    //         dataIndex: "dTrial",
    //         key: "dTrial",
    //     },
    //   ]
    
    //   const designGroupColumns = dataSource[0]?.designGroups?.map((group, index) => ({
    //     title: `${getDesignGroupType(group.name)}`,
    //    children: children
    //   })) || [];

    // Function to preprocess dataSource and flatten design group properties
  const preprocessDataSource = (dataSource) => {
    return dataSource.map(item => {
      const newItem = { ...item }; // Clone the item
      item.designGroups.forEach(group => {
        const type = getDesignGroupType(group.name);
        if (type) {
          newItem[`${type}_bTrial`] = group.bTrial;
          newItem[`${type}_hTrial`] = group.hTrial;
          newItem[`${type}_dTrial`] = group.dTrial;
          newItem[`${type}_pw`] = group.pw;
        }
      });

      return newItem;
    });
  };

  // Processed dataSource
  const processedDataSource = preprocessDataSource(dataSource);

  // Define the base columns
  const baseColumns = [
    {
      title: "Mix #",
      dataIndex: "product",
      key: "product",
    },
    // Other base columns...
  ];

  // Function to create children columns for group types
  const createChildrenColumns = (type) => [
    {
      title: "bTrial (m)",
      dataIndex: `${type}_bTrial`,
      key: `${type}_bTrial`,
    },
    {
      title: "hTrial (m)",
      dataIndex: `${type}_hTrial`,
      key: `${type}_hTrial`,
    },
    {
      title: "dTrial (m)",
      dataIndex: `${type}_dTrial`,
      key: `${type}_dTrial`,
    },
    {
        title: "pw",
        dataIndex: `${type}_pw`,
        key: `${type}_pw`,
      },
  ];

  // Define grouped columns based on the designGroup types found in the dataSource
  const designGroupTypes = new Set(processedDataSource.flatMap(item =>
    item.designGroups.map(group => getDesignGroupType(group.name))
  ));

  const designGroupColumns = Array.from(designGroupTypes).filter(Boolean).map(type => ({
    title: type,
    children: createChildrenColumns(type),
  }));

    const columns = [
        {
            title: "Mix #",
            dataIndex: "product",
            key: "product",
        },
        {
            title: "Column Types",
            children: designGroupColumns,
        },
        columnHeadings.iC,
        columnHeadings.iSw,
        columnHeadings.iTotal,
        columnHeadings.dollarC,
        columnHeadings.dollarSw,
        columnHeadings.dollarTotal
          
    ]
    return(
        <div>
            <Table dataSource = {processedDataSource} columns = {columns} scroll={{ y: 240 }}/>
        </div>
    )
}

export default TempTable;