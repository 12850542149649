import {React, useState, useEffect} from "react";
import { Scatter } from "react-chartjs-2";
import useFormContext from "../../hooks/useFormContext";
import BarChartPlot from "./Plots/BarChartPlot";
import ScatterPlot from "./Plots/ScatterPlot";
import ScatterPlotCost from "./Plots/ScatterPlotCost";
import BarChartDifferential from "./Plots/BarChartDifferential";
import CountUp from 'react-countup';
import { Col, Row, Spin, Statistic } from "antd";
import AppendixTable from "./AppendixTable";
const formatter = (value) => <CountUp end={value} separator="," />;
const Plots = () => {

  
  const [isLoading, setIsLoading] = useState(true);
  const { buildingValues } = useFormContext();

  useEffect(() => {
    // Set a timer to change isLoading to false after 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer); // Clear the timer if the component unmounts
  }, []); // Empty dependency array means this runs once on mount

  const message = <Statistic title="Crunching these many design combinations for you" value={buildingValues.length} formatter={formatter} />

  if (isLoading) {
    return <Spin tip={message} size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div />
      </Spin>
   
  }

  


  const graphStyle = {
    height: 400,
    marginTop: 20
  }
  return (
    <div >
      <Row gutter={[20, 16]}>
        <Col span={12}>
          <div style={graphStyle}>
            <ScatterPlot />
          </div>
        </Col>
        <Col span={12}>
          <div style={graphStyle}>
            <BarChartPlot />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <div style={graphStyle}>
            <BarChartDifferential />
          </div>
        </Col>
        <Col span={12}>
          <div style={graphStyle}>
            <ScatterPlotCost />
          </div>
        </Col>
      </Row>

      <div style={{ alignContent: "center" , margin: 10}}>
        <AppendixTable />
      </div>
    </div>
  );
};

export default Plots;
