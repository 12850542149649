import { Carousel, Button, Table } from "antd";
import useFormContext from "../../hooks/useFormContext";
import React, { useState, useRef } from "react";
import columnHeadings from "../Constants/ColumnHeadings";
import { Typography } from "antd";

const { Title } = Typography;

const MatrixByBuilding = () => {
  const { buildingValues } = useFormContext();

  const columns = [
    {
      title: "Mix Design #",
      dataIndex: "product",
      key: "product",
    },
    columnHeadings.iCTons,
    columnHeadings.iSwTons,
    columnHeadings.iTotalTons,
    columnHeadings.dollarCThousands,
    columnHeadings.dollarSwThousands,
    columnHeadings.dollarFwThousands,
    columnHeadings.dollarTotalThousands,
  ];

  return (
    <div>
      <Table dataSource={buildingValues} columns={columns} pagination={true} />
    </div>
  );
};

export default MatrixByBuilding;
