import React, { useState } from "react";
import { Scatter } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
import useFormContext from "../../../hooks/useFormContext";
import { Spin } from "antd";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const ScatterPlotCost = () => {
  const { buildingValues, concreteInfo } = useFormContext();
  const [loading, setLoading] = useState(false);

  const getUnitCostOfConcrete = (id) => {
    return concreteInfo.find(ele => ele.id == id).cost;
  }
  

  const datasets = {
    'uhpc': { points: [], label: 'UHPC', pointStyle: 'circle' },
    'hpc': { points: [], label: 'HPC', pointStyle: 'rect' },
    'nsc': { points: [], label: 'NSC', pointStyle: 'triangle' },
    'plc': { points: [], label: 'PLC', pointStyle: 'star' },
  };

   // Assign points to the respective dataset based on the product ID
   buildingValues.forEach(comb => {
    const mix = concreteInfo.find(mix => mix.id == comb.product);
    if (mix) {
      const datasetKey = mix.materialCategory.toLowerCase();
      datasets[datasetKey].points.push({ x: getUnitCostOfConcrete(comb.product), y: comb.dollarTotal, productId: comb.product });
    }
  });

  const categoryColors = {
    'uhpc': { pointColor: 'red', borderColor: 'darkred' },
    'hpc': { pointColor: 'blue', borderColor: 'darkblue' },
    'nsc': { pointColor: 'green', borderColor: 'darkgreen' },
    'plc': { pointColor: 'orange', borderColor: 'darkorange' },
  };
  
  const chartDatasets = Object.values(datasets).map(dataset => ({
    label: dataset.label,
    data: dataset.points,
    pointStyle: dataset.pointStyle,
    backgroundColor: categoryColors[dataset.label.toLowerCase()].pointColor, // Set point color
    borderColor: categoryColors[dataset.label.toLowerCase()].borderColor, // Set border color
    pointBorderColor: categoryColors[dataset.label.toLowerCase()].borderColor, // Set point border color
    pointBackgroundColor: categoryColors[dataset.label.toLowerCase()].pointColor, // Set point background color
  }));


  const data = { datasets: chartDatasets };

  const options = {
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Unit cost (USD / yd3)",
        },
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "$ Total (x1000 USD)",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const point = context.raw;
            return `Mix #: ${point.productId}\nUnit Cost: ${point.x}\n$Total: ${point.y}`;
          }
        }
      },
      title: {
        display: true,
        text: "Total cost Vs Unit cost"
      }
    }
  };

  return <Scatter data={data} options={options} />;
};

export default ScatterPlotCost;
